import { ScrollView, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import useTicketStore, { TicketInformation } from '../stores/TicketStore'
import GlobalStyles from '../styles/GlobalStyles'
import { useDeviceProperties } from '../hooks/useDeviceProperties'

const TicketInfoTable = () => {
    const { moderateScale } = useDeviceProperties();
    const { ticketTypeInformation } = useTicketStore(state => ({
        ticketTypeInformation: state.ticketTypeInformation
    }))

    return (
        <ScrollView style={{ marginTop: 10, padding: 20 }}>
            {
                ticketTypeInformation.map((ticket: TicketInformation, index: number) => {
                    return (
                        <View key={`ticket_${index}`} style={{ justifyContent: 'space-between', flexDirection: 'row', marginBottom: 10 }}>
                            <Text style={[GlobalStyles.grayBoldText, { textAlign: 'left', fontSize: moderateScale(20, .05) }]} >{ticket.type}:</Text>
                            <Text style={[GlobalStyles.grayMediumText, { textAlign: 'right', fontSize: moderateScale(20, .05) }]} >{ticket.countScanned} von {ticket.count}</Text>
                        </View>
                    )
                })
            }
        </ScrollView>
    )
}

export default TicketInfoTable

const styles = StyleSheet.create({})