import useSystemStore from "../stores/SystemStore";

const ENV = {
    dev: {
        EXPO_PUBLIC_URL_JSON_TICKETS: 'https://dev.oe-konzept.de/scantickets2014/www/mobile/tickets.php',
        EXPO_PUBLIC_URL_REQUEST_BASIC_VA_DATA:
            'https://dev.oe-konzept.de/scantickets2014/www/mobile/find_va.php',
        EXPO_PUBLIC_URL_REQUEST_TICKET_DATA:
            'https://dev.oe-konzept.de/scantickets2014/www/mobile/tickets.php',
        EXPO_PUBLIC_EVENT_URL: 'https://dev.oe-konzept.de/scantickets2014/www/mobile',
    },
    prod: {
        EXPO_PUBLIC_URL_JSON_TICKETS: 'https://www.scantickets.de/mobile/tickets.php',
        EXPO_PUBLIC_URL_REQUEST_BASIC_VA_DATA:
            'https://www.scantickets.de/mobile/find_va.php',
        EXPO_PUBLIC_URL_REQUEST_TICKET_DATA:
            'https://www.scantickets.de/mobile/tickets.php',
        EXPO_PUBLIC_EVENT_URL: 'https://www.scantickets.de/mobile',
    },
    emergency: {
        EXPO_PUBLIC_URL_JSON_TICKETS: 'http://scantickets.oe-konzept.de/mobile/tickets.php',
        EXPO_PUBLIC_URL_REQUEST_BASIC_VA_DATA: 'http://scantickets.oe-konzept.de/mobile/find_va.php',
        EXPO_PUBLIC_URL_REQUEST_TICKET_DATA: 'http://scantickets.oe-konzept.de/mobile/tickets.php',
        EXPO_PUBLIC_EVENT_URL: 'http://scantickets.oe-konzept.de/mobile',
    },
} as const;

/**
 * Differs between dev and prod and returns according env vars
 *
 * @returns {Object} Environmentvariables
 */

const getEnvVars = () => {
    if (useSystemStore.getState().emergencyActive) {
        console.log('👉 Script environment.ts line 43 NOTFALLSYSTEM');
        return ENV.emergency
    }

    if (__DEV__) {
        return ENV.dev
    }
    return ENV.prod
}

export default getEnvVars