import { useWindowDimensions } from "react-native"

/**
 * Hook improves the responsiveness of the app by providing the width of the device and a function to scale components
 */

type TDeviceProperties = {
    displayLargeComponents: { display: 'flex' | 'none' },
    displaySmallComponents: { display: 'flex' | 'none' },
    width: number,
    largeScreen: boolean,
    moderateScale: (size: number, factor?: number) => number,
    verticalScale: (size: number) => number,
    horizontalScale: (size: number) => number,
};

export const useDeviceProperties = (): TDeviceProperties => {
    const { width, height } = useWindowDimensions();

    const guidelineBaseWidth = 300;
    const guidelineBaseHeight = 612;

    const horizontalScale = (size) => (width / guidelineBaseWidth) * size;
    const verticalScale = (size) => (height / guidelineBaseHeight) * size;
    const moderateScale = (size, factor = 0.5) => size + (horizontalScale(size) - size) * factor;


    return {
        displayLargeComponents: width > 900 ? { display: 'flex' } : { display: 'none' },
        displaySmallComponents: width < 900 ? { display: 'flex' } : { display: 'none' },
        width,
        largeScreen: width > 900,
        moderateScale,
        verticalScale,
        horizontalScale,
    };
}