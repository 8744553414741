import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

const ColorStripe = () => {
  return (
    <View style={ styles.container }>
      <View style={ styles.stripe1 }></View>
      <View style={ styles.stripe2 }></View>
      <View style={ styles.stripe3 }></View>
      <View style={ styles.stripe4 }></View>
      <View style={ styles.stripe5 }></View>
      <View style={ styles.stripe6 }></View>
      <View style={ styles.stripe7 }></View>
      <View style={ styles.stripe8 }></View>
      <View style={ styles.stripe9 }></View>
      <View style={ styles.stripe10 }></View>
    </View>
  )
}

export default ColorStripe

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        width: '100%',
        height: 7,
        backgroundColor: 'red',
    },
    stripe1: {
        backgroundColor: '#00767D',
        flex: 10 
    },
    stripe2: {
        backgroundColor: '#D5C800',
        flex: 5 
    },
    stripe3: {
        backgroundColor: '#C91C45',
        flex: 4 
    },
    stripe4: {
        backgroundColor: '#F4A100',
        flex: 7 
    },
    stripe5: {
        backgroundColor: '#1F777C',
        flex: .4 
    },
    stripe6: {
        backgroundColor: '#00B2DE',
        flex: 2 
    },
    stripe7: {
        backgroundColor: '#E53A67',
        flex: 3 
    },
    stripe8: {
        backgroundColor: '#BCCD00',
        flex: 2 
    },
    stripe9: {
        backgroundColor: '#00767D',
        flex: 2 
    },
    stripe10: {
        backgroundColor: '#00A1A5',
        flex: 7 
    },
})