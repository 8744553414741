import { Pressable, Text, TextStyle, View, ViewStyle } from "react-native";
import GlobalStyles from "../styles/GlobalStyles";
import { TouchableOpacity } from "react-native-gesture-handler";

type STButtonProps = {
  title?: string;
  onPress: () => void;
  buttonStyles?: ViewStyle | ViewStyle[];
  labelStyles?: TextStyle | TextStyle[];
  children?: React.ReactNode;
};

/**
 * Global ScanTicketsButton
 */

const STButton = ({
  title = null,
  onPress,
  buttonStyles = {},
  labelStyles = {},
  children = null,
}: STButtonProps) => {
  return (
    <Pressable
      style={({ pressed }) => [GlobalStyles.button, buttonStyles, { opacity: pressed ? 0.5 : 1 }]}
      onPress={onPress}
    >
      {title ? <Text style={[GlobalStyles.buttonText, labelStyles]}>{title}</Text> : children}
    </Pressable >
  );
};

export default STButton;
