import AsyncStorage from '@react-native-async-storage/async-storage'

const useSTStorage = () => {
    const dumpAllDataFromStorage = () => {
        AsyncStorage.getAllKeys((err, keys) => {
            AsyncStorage.multiGet(keys, (error, stores) => {
                stores.map((result, i, store) => {
                    console.log('👉 Script useStStorage.ts line 8 ', { [store[i][0]]: store[i][1] });
                    return true
                })
            })
        })
    }

    const clearAllDataFromStorage = async () => {
        await AsyncStorage.clear()
    }

    return {
        dumpAllDataFromStorage,
        clearAllDataFromStorage
    }
}

export default useSTStorage