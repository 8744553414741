import { create } from 'zustand';
import methods from '../util/APIKit';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getTranslation } from '../util/languages';
import getEnvVars from '../util/environment';
import useAlertStore, { AlertStore } from './AlertStore';
import useAdmissionStore from './AdmissionStore';

type EventAuthPair = {
    event_key: string;
    auth_key: string;
}

type EventData = {
    src_id: string;
    va_abbreviation: string;
    name: string;
    date_start: string;
    date_end: string;
    unlocked: boolean;
    event_key: string;
}

export interface EventState {
    event_key: string;
    auth_key: string;
    eventIsValid: boolean;
    eventValidationError: boolean;
    setEventIsValid: (eventIsValid: boolean) => void;
    setEventValidationError: (eventValidationError: boolean) => void;
    setEventKey: (event_key: string) => void;
    setAuthKey: (auth_key: string) => void;
    findEvent: () => void;
    isLoading: boolean;
    eventInformation: EventData;
    eventAuthPairs?: EventAuthPair[] | [];
    getEventsAuthKey: () => string | null;
    data: EventData,
    events: EventData[],
    successfullyValidatedEvents: string[],
}

type EventResponse = {
    success?: string;
    invalid?: number;
    error?: number;
    data?: EventData
}

type errorCodes = {
    [key: number]: string;
}

export const errorCodes: errorCodes = {
    3002: getTranslation('error_3002'),
    3003: getTranslation('error_3003'),
    3004: getTranslation('error_3004'),
    3005: getTranslation('error_3005'),
    4006: getTranslation('error_4006'),
}


const useEventStore = create<EventState>()(
    devtools(
        persist((set) => ({
            event_key: '',
            auth_key: '',
            isLoading: false,
            eventIsValid: false,
            eventValidationError: false,
            data: {} as EventData,
            eventInformation: {} as EventData,
            successfullyValidatedEvents: [],
            events: [],
            eventAuthPairs: [],
            setEventValidationError(eventValidationError) {
                set({ eventValidationError })
            },
            setEventIsValid(eventIsValid) {
                set({ eventIsValid })
            },
            setEventKey: (event_key: string) => {
                set({ event_key })
            },
            setAuthKey: (auth_key: string) => {
                set({ auth_key })
            },
            // If the event already has an auth key, return it. Otherwise, return null.
            getEventsAuthKey: () => {
                const eventAuthPairs = useEventStore.getState().eventAuthPairs;
                if (eventAuthPairs.length == 0) return null;
                const event_key = useEventStore.getState().event_key;
                const auth_key = eventAuthPairs.find((pair: EventAuthPair) => pair.event_key == event_key)?.auth_key;
                return auth_key;
            },
            findEvent: async () => {
                const { EXPO_PUBLIC_URL_REQUEST_BASIC_VA_DATA } = getEnvVars();
                if (useEventStore.getState().event_key == '' || useEventStore.getState().auth_key == '') return;
                const query = {
                    va_key: useEventStore.getState().event_key,
                    auth_key: useEventStore.getState().auth_key
                }
                try {
                    set({ isLoading: true });
                    // Wenn Veranstaltung schon einmal gefunden wurde, dann nicht nochmal suchen
                    if (useEventStore.getState().successfullyValidatedEvents.includes(query.va_key)) {
                        const event = useEventStore.getState().events.find((event: EventData) => event.event_key == query.va_key) as EventData;
                        set({ eventInformation: event, isLoading: false, eventIsValid: true });
                        return;
                    } else {
                        useAdmissionStore.getState().setAdmissionType('online')
                    }

                    const responseData = await methods.get<EventResponse>({ url: EXPO_PUBLIC_URL_REQUEST_BASIC_VA_DATA, query });
                    // Veranstaltung wurde gefunden und wurde verifiziert
                    if (responseData.success && responseData.success == 'true') {
                        if (!useEventStore.getState().successfullyValidatedEvents.includes(query.va_key)) {
                            set({ successfullyValidatedEvents: [...useEventStore.getState().successfullyValidatedEvents, query.va_key] });
                        }
                        set({ isLoading: false, data: responseData.data, eventIsValid: true });
                        const arr = [query.va_key, query.auth_key];
                        // Keine doppelten oder leeren Einträge
                        if (query.va_key === '' || query.auth_key === '') return;
                        // In AuthPair in den Storage schreiben, wenn nicht schon vorhanden
                        if (!useEventStore.getState().eventAuthPairs.find((pair: EventAuthPair) => pair.event_key == query.va_key)) {
                            set({ eventAuthPairs: [...useEventStore.getState().eventAuthPairs, { event_key: query.va_key, auth_key: query.auth_key }] })
                        }
                        // Event in den Storage schreiben, wenn nicht schon vorhanden
                        if (!useEventStore.getState().events.find((event: EventData) => event.src_id == responseData.data.src_id)) {
                            responseData.data = { ...responseData.data, ...{ event_key: query.va_key } };
                            set({ events: [...useEventStore.getState().events, responseData.data] })
                        }
                        // Event Informationen in den Store schreiben
                        const event = useEventStore.getState().events.find((event: EventData) => event.event_key == query.va_key) as EventData;
                        set({ eventInformation: event });
                        return;
                        // Daten sind nicht valide
                    } else if (responseData.invalid) {
                        set({ isLoading: false, eventValidationError: true });
                        useAlertStore.getState().setAlert({ message: errorCodes[responseData.invalid], type: 'error' })
                        return;
                        // Fehler passiert
                    } else if (responseData.error) {
                        set({ isLoading: false, eventValidationError: true });
                        useAlertStore.getState().setAlert({ message: errorCodes[responseData.error], type: 'error' })
                        return;
                    }
                    set({ isLoading: false });
                } catch (error) {
                    set({ isLoading: false, eventValidationError: true });
                    useAlertStore.getState().setAlert({ message: getTranslation('error_common'), type: 'error' })
                    console.log(error);
                }
            }
        }
        ), {
            name: 'event-storage',
            // Hier wird festgelegt, welche Daten auf dem Gerät gespeichert werden sollen
            partialize: (state) => ({
                eventAuthPairs: state.eventAuthPairs,
                events: state.events,
                eventInformation: state.eventInformation,
                successfullyValidatedEvents: state.successfullyValidatedEvents
            }),
            storage: createJSONStorage(() => AsyncStorage)
            /* Object.fromEntries(
                Object.entries(state).filter(([key]) => !['foo'].includes(key))
            ), */
        }
        )
    )
)


export default useEventStore;