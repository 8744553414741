import AuthView from "../../components/AuthView";
import { useAuthView } from "../../hooks/useAuthView";
import useSTStorage from "../../hooks/useStStorage";



const VAKeyView = () => {
  useAuthView("event");

  const { dumpAllDataFromStorage } = useSTStorage();

  // dumpAllDataFromStorage();

  return (
    <>
      <AuthView type="event" />
    </>
  );
};

export default VAKeyView;