import { Pressable, ScrollView, StyleSheet, Text, View, ViewStyle, NativeSyntheticEvent, NativeScrollEvent, Platform, Dimensions } from "react-native";
import { useDeviceProperties } from "../../hooks/useDeviceProperties";
import useAdmissionStore from "../../stores/AdmissionStore";
import { getLanguage, getTranslation } from "../../util/languages";
import GlobalStyles from "../../styles/GlobalStyles";
import STButton from "../../components/STButton";
import { MaterialIcons } from '@expo/vector-icons';

import PlugOffline from "../../components/PlugOffline";
import PlugOnline from "../../components/PlugOnline"
import Refresh from "../../components/Refresh"

import FooterBar from "../../components/FooterBar";
import LoadingScreen from "../../components/LoadingScreen";
import useTicketStore, { TicketInformation, TicketsStore } from "../../stores/TicketStore";
import useSTStorage from "../../hooks/useStStorage";
import { useEffect, useState, useRef } from "react";
import useEventStore from "../../stores/EventStore";
import moment from "moment";
import 'moment/locale/de';
import TicketInfoTable from "../../components/TicketInfoTable";
import CameraInputField from "../../components/CameraInputField";
import TicketResultContainer from "../../components/TicketResultContainer";
import AlertStore from "../../stores/AlertStore";
import useSystemStore from "../../stores/SystemStore";
import { useNavigation } from "@react-navigation/native";
import { useWebScrollEvents } from "../../hooks/useWebScrollEvents";
import useAdmissionControl from "../../hooks/useAdmissionControl";


/**
 * Header component for the overview screen
 */
const Header = ({ admissionType }) => {
  const { moderateScale, displayLargeComponents } = useDeviceProperties();
  const { eventInformation } = useEventStore((state) => ({
    eventInformation: state.eventInformation,
  }));

  let dateString = moment(eventInformation.date_start).locale('de').format(`dddd, DD.MM.YYYY, HH:mm${getLanguage() != 'de' ? ' a' : ''}`) + (getLanguage() == 'de' ? ' Uhr' : '')

  if (eventInformation.date_end) {
    const dateStringEnd = moment(eventInformation.date_end).locale('de').format(`dddd, DD.MM.YYYY, HH:mm${getLanguage() != 'de' ? ' a' : ''}`) + (getLanguage() == 'de' ? ' Uhr' : '')
    if (dateStringEnd != dateString) {
      dateString = dateString + ' - ' + dateStringEnd;
    }
  }

  return (
    <View style={headerStyles.container} id="header">
      <Text style={[GlobalStyles.blackMediumText]}>
        {admissionType === "online"
          ? getTranslation("dataFoundLocationOnline")
          : getTranslation("dataFoundLocationOffline")}
      </Text>
      <Text
        style={[
          GlobalStyles.blackMediumText,
          headerStyles.headline,
          { fontSize: moderateScale(18) },
        ]}
      >
        {eventInformation?.name}
      </Text>
      <Text style={[headerStyles.subline, { fontSize: Platform.OS === 'web' ? 28 : 14 }]}>{dateString}</Text>
      <View style={[headerStyles.line, displayLargeComponents]} ></View>
    </View>
  );
};

const headerStyles = StyleSheet.create({
  container: {
    padding: 20,
  },
  headline: {
    fontFamily: "ClearSansBold",
  },
  subline: {
    fontFamily: "ClearSansItalic",
    textAlign: "center",
  },
  line: {
    height: 2,
    width: '100%',
    backgroundColor: '#d2d2d2',
    marginVertical: 5
  }
});

/**
 * Infobar for phones
 */
const InfoBar = ({ style = null }: { style?: ViewStyle }) => {
  const { moderateScale } = useDeviceProperties();
  const { getTicketCountScanned, getTicketCountTotal } = useTicketStore((state: TicketsStore) => ({
    getTicketCountScanned: state.getTicketCountScanned,
    getTicketCountTotal: state.getTicketCountTotal,
  }));
  const navigation = useNavigation();

  return (
    <View style={[infoBarStyles.container, style]} id="infobar">
      <View style={infoBarStyles.whiteBar} />
      <View style={infoBarStyles.textContainerWrapper}>
        <View style={infoBarStyles.textContainer}>
          <Text style={[GlobalStyles.blackBoldText, { fontSize: moderateScale(14) }]}>
            {`${getTicketCountScanned()} ${getTranslation('label_of')} ${getTicketCountTotal()}`}
          </Text>
          <Text style={[GlobalStyles.blackMediumText, { fontSize: moderateScale(14) }]}>
            {' ' + getTranslation('label_scanned_tickets')}
          </Text>
        </View>
        <STButton
          title=""
          onPress={() => { navigation.navigate('TicketInformation') }}
          labelStyles={[GlobalStyles.symbol, { color: 'white', fontSize: 15 }]}
          buttonStyles={[infoBarStyles.buttonStyle]}
        />
      </View>
      <View style={infoBarStyles.blackBar} />
    </View>
  );
};

const infoBarStyles = StyleSheet.create({
  container: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderBottomColor: "white"
  },
  textContainerWrapper: {
    flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 10,
    paddingVertical: 10
  },
  buttonStyle: {
    padding: 0,
    backgroundColor: "#1F3F46",
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
  },
  textContainer: {
    marginVertical: 5,
    flexDirection: "row",
    alignItems: "center",
  },
  whiteBar: {
    height: 1,
    backgroundColor: "white",
  },
  blackBar: {
    height: 1,
    backgroundColor: "black",
  }
})

/**
 * Overview screen component
 */
const OverView = () => {
  const scrollRef = useRef(null);


  // Geräteeigenschaften
  const { moderateScale,
    displayLargeComponents,
    verticalScale,
    horizontalScale,
    width,
    displaySmallComponents,
    largeScreen } = useDeviceProperties();

  // Zustand des Einlasses (online/offline)
  const { admissionType, switchingState } = useAdmissionStore((state) => ({
    admissionType: state.admissionType,
    switchingState: state.switchingState,
  }));
  // AsyncStorage auslese
  const { dumpAllDataFromStorage, clearAllDataFromStorage } = useSTStorage();

  const { setAdmissionType } = useAdmissionControl();

  // Ticketdaten
  const {
    isLoading,
    getTickets,
    syncAllTicketsOfEventToServer,
    getTicketCountTotal,
    getTicketCountScanned,
    barcode,
    setBarcode,
    validateBarcode,
    resetBarcodeValidationSuccess,
  } = useTicketStore((state) => ({
    isLoading: state.isLoading,
    getTickets: state.getTickets,
    getTicketCountTotal: state.getTicketCountTotal,
    syncAllTicketsOfEventToServer: state.syncAllTicketsOfEventToServer,
    getTicketCountScanned: state.getTicketCountScanned,
    barcode: state.barcode,
    setBarcode: state.setBarcode,
    validateBarcode: state.validateBarcode,
    resetBarcodeValidationSuccess: state.resetBarcodeValidationSuccess,
  }));

  useEffect(() => {
    getTickets();
    useSystemStore.getState().setStep(3);
  }, []);

  useEffect(() => {
    if (useSystemStore.getState().step === 3) {
      resetBarcodeValidationSuccess();
      scrollRef.current?.scrollTo({ x: 0, animated: true });
      return
    }
  }, [useSystemStore.getState().step]);


  useEffect(() => {
    if (barcode.length > 0) {
      validateBarcode();
    }
  }, [barcode]);

  const cameraFieldRef = useRef(null);

  // Wechsel zwischen Online- und Offline-Einlass
  const switchStatus = () => {
    setAdmissionType(admissionType === "online" ? "offline" : "online");
  };

  // Aktualisieren der Ticketdaten
  const refreshTicketData = async () => {
    const res = await syncAllTicketsOfEventToServer();
    getTickets(res ? true : false);
  };

  const finalWidth = largeScreen ? width * .63 : width * .89;

  const webProps = useWebScrollEvents({
    onMomentumScrollEnd: (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      if (event.nativeEvent.contentOffset.x > finalWidth && Platform.OS === 'web') {
        cameraFieldRef.current?.focusField();
      }
    },
    onScroll: (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      if (event.nativeEvent.contentOffset.x < finalWidth) {
        setBarcode('');
      }
    }
  })

  // Wenn gerade geladen wird, dann entsprechenden Ladebildschirm anzeigen
  if (switchingState) return (
    <LoadingScreen type="onlinestate" />
  )

  if (isLoading) return (
    <LoadingScreen type="ticketrefresh" />
  )


  let scrollHeight = null;
  if (Platform.OS === 'web') {
    scrollHeight = { height: Dimensions.get('window').height - 300 }
  }


  return (
    <View style={styles.container} id="outer-wrapper">
      {/* Überschrift mit Veranstaltungstitel, Beschreibung etc. */}
      <Header admissionType={admissionType} />
      <View style={[styles.admissionContainer, styles.paddingHorizontal]} id="infopanel">
        {/* Infopanel mit den Ticketdaten (Einlassdaten -> Gescannte Tickets etc.) etc. */}
        <View style={[styles.infoPanel, displayLargeComponents]} id="left-infoarea">
          <View style={{ backgroundColor: 'white', borderRadius: 4, flex: 1, paddingBottom: 10 }}>
            <Text style={[GlobalStyles.grayBoldText, { fontSize: moderateScale(14), paddingTop: 20 }]}>
              {`${getTicketCountScanned()} ${getTranslation('label_of')} ${getTicketCountTotal()}`}
            </Text>
            <Text style={[GlobalStyles.grayMediumText, { fontSize: moderateScale(8) }]}>
              {getTranslation('label_scanned_tickets')}
            </Text>
            {/* Alle einzelnen Ticketarten und deren Scanstatus ausgeben */}
            <TicketInfoTable />
          </View>
        </View>
        <View style={[styles.admissionPanel]} id="right-infoarea">
          {/* Info über Scananzahl und Gesamttickets (NUR Smartphone) */}
          <InfoBar style={displaySmallComponents} />

          {/* Einlassbutton */}
          <ScrollView
            horizontal
            showsHorizontalScrollIndicator={false}
            snapToInterval={finalWidth}
            decelerationRate={'fast'}
            ref={scrollRef}
            scrollEventThrottle={500}
            snapToAlignment="start"
            scrollEnabled={false}
            {...webProps}
          >

            {/* Hauptscreen mit Status und Einlassbutton */}
            <View style={[styles.mainAdmissionScreen, { width: finalWidth, marginRight: 32 }]}>
              <STButton
                buttonStyles={[styles.buttonStyle, { paddingVertical: verticalScale(5), marginTop: largeScreen ? 0 : 20 }]}
                onPress={() => {
                  useSystemStore.getState().setStep(4);
                  scrollRef.current?.scrollTo({ x: finalWidth * 2, animated: true })
                }
                }
              >
                <Text style={[GlobalStyles.whiteMediumText, { fontSize: moderateScale(18, .2) }]}>
                  {getTranslation("label_start_admission")}
                </Text>
                <MaterialIcons
                  name="navigate-next"
                  size={moderateScale(30, .2)}
                  color="white"
                  style={{ marginRight: -20 }} />
              </STButton>
              {/* <STButton title="Debug" onPress={() => AlertStore.getState().setAlert({ message: 'Funzt' })} /> */}
              {/*  Statebuttons (Ticket-Codes aktualisieren, zum Online/Offline-Einlass)  */}
              <View style={styles.stateContainerWrapperWrapper}>
                <View style={[styles.stateContainerWrapper, { padding: verticalScale(20), gap: 30 }, largeScreen ? { paddingHorizontal: horizontalScale(4) } : null]}>
                  {
                    [Refresh, PlugOffline, PlugOnline].map((Icon, index) => {
                      if (Icon === PlugOffline && admissionType === 'online') return null;
                      if (Icon === PlugOnline && admissionType === 'offline') return null;
                      if (Icon === Refresh && admissionType === 'online') return null;
                      return (
                        <Pressable key={`switch_${index}`} onPress={Icon === PlugOffline || Icon === PlugOnline ? switchStatus : refreshTicketData} style={({ pressed }) => [styles.stateContainer, { opacity: pressed ? .5 : 1 }, { flexDirection: largeScreen ? 'row' : 'column', flexWrap: largeScreen ? 'wrap' : 'nowrap', justifyContent: 'center' }]} >
                          <Icon width={moderateScale(75, .2)} height={moderateScale(75, .2)} />
                          <Text style={[GlobalStyles.blackMediumText, { fontSize: moderateScale(12, .2), flexWrap: 'wrap' }]}>
                            {getTranslation(Icon === PlugOffline || Icon === PlugOnline ? (admissionType === 'offline' ? "label_switch_to_online" : "label_switch_to_offline") : "label_refresh_ticket_codes")}
                          </Text>
                        </Pressable>
                      )
                    }
                    )
                  }
                </View>
              </View>
            </View>
            {/* Ticketscan-Screen */}
            <ScrollView style={[styles.ticketCheckScreen, { position: 'relative', width: finalWidth, marginTop: largeScreen ? 0 : 20 }, scrollHeight]}>
              <CameraInputField ref={cameraFieldRef} type="ticket" styles={{ minWidth: 340, width: '100%' }} inputStyles={{ fontSize: moderateScale(20, .05) }} height={verticalScale(45)} placeholder={getTranslation('ticket_code_placeholder')} />
              <TicketResultContainer />
            </ScrollView>
          </ScrollView>
        </View>
      </View >
      <FooterBar />
    </View >
  );
};

export default OverView;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#eee",
    position: 'relative',
  },
  mainAdmissionScreen: {
    flex: 1,
    paddingRight: 5
  },
  ticketCheckScreen: {
    flex: 1,
    paddingLeft: 0,
    paddingBottom: 20
  },
  stateContainerWrapper: {
    backgroundColor: 'white',
    borderRadius: 4,
    flexDirection: 'row',
  },
  stateContainerWrapperWrapper:
  {
    flex: 1,
    justifyContent: 'flex-end',
    paddingBottom: 20
  },
  stateContainer: {
    alignItems: 'center',
    gap: 10,
    flex: 1,
  },
  buttonStyle: {
    backgroundColor: "#1F3F46",
    marginVertical: 20,
    justifyContent: 'space-between',
    flexDirection: 'row',
    height: 'auto'
  },
  admissionContainer: {
    flex: 1,
    flexDirection: "row",
  },
  paddingHorizontal: {
    paddingHorizontal: 20,
  },
  infoPanel: {
    flex: 1,
    paddingBottom: 20,
    paddingRight: 20,
  },
  admissionPanel: {
    flex: 2,
  },
  switch: {
    padding: 20,
  },
});
