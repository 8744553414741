import axios from "axios";
import qs from 'query-string'

// Create axios instance
const APIKit = axios.create({
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    },
});


// Interface for get request data
interface IGet {
    url: string;
    query?: Record<string, any>;
}

// Interface for post request data
interface IPost {
    url: string;
    query?: Record<string, any>;
    data?: any;
}

let controller = new AbortController();

// Function to get data from ScanTickets API
const get = async <T>({ url, query }: IGet): Promise<T> => {
    let options = {}
    // Wenn alles Tickets gelesen werden sollen, muss der alte Request abgebrochen werden
    if (query.method == 'read') {
        controller.abort();
        controller = new AbortController();
        options = { signal: controller.signal }
    }
    const queryString = `?${qs.stringify(query)}`;
    const response = await APIKit.get<T>(`${url}${queryString}`, options);
    return response.data;
}

// Function to post data to ScanTickets API
const post = async <T>({ url, data, query = {} }: IPost): Promise<T> => {
    const queryString = `?${qs.stringify(query)}`;
    const response = await APIKit.post<T>(`${url}${queryString}`, data);
    return response.data;
}

// Define all possible methods
const methods = { get, post };


export default methods;