import { Platform, StyleSheet, ScrollView, View, Pressable, Text } from 'react-native'
import React from 'react'
import { createStackNavigator } from '@react-navigation/stack';
import VAKeyView from '../Screens/auth/VAKeyView';
import BarcodeScannerView from '../Screens/BarcodeScannerView';
import AuthKeyView from '../Screens/auth/AuthKeyView';
import LawPage from '../Screens/modal/Law';
import Ionicons from "react-native-vector-icons/Ionicons";
import Guide from '../Screens/modal/Guide';
import { getTranslation } from '../util/languages';
import { useNavigation } from '@react-navigation/native';
import OverView from '../Screens/admission/OverView';
import TicketInformation from '../Screens/modal/TicketInformation';
import TicketScanInformation from '../Screens/modal/TicketScanInformation';
import useTicketStore from '../stores/TicketStore';
import useEventStore from '../stores/EventStore';
import Indicator from './Indicator';
import Alert from './Alert';
import useSystemStore from '../stores/SystemStore';
import GlobalStyles from '../styles/GlobalStyles';


const Stack = createStackNavigator();


const AppNavigator = () => {
    const navigation = useNavigation();

    type BackButtonProps = {
        onPress?: () => void;
        source?: 'overview' | 'modal';
    };

    const BackButton = ({ onPress = null, source = 'overview' }: BackButtonProps) => {
        return (
            <View>
                <Pressable
                    style={({ pressed }) => [{ backgroundColor: 'transparent', borderRadius: 5, opacity: pressed ? 0.5 : 1 }]}
                    onPress={onPress ? onPress : () => {
                        if (source === 'overview') {
                            useTicketStore.getState().setBarcode('')
                            if (useSystemStore.getState().step === 4) {
                                useSystemStore.getState().setStep(3)
                                return
                            }
                            useEventStore.getState().setEventKey('')
                            useEventStore.getState().setAuthKey('')
                            navigation.navigate('VAKeyView');
                            return
                        }
                        navigation.goBack()
                    }}
                >
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 5, marginLeft: 10 }}>
                        <Ionicons name="chevron-back" size={22} color="#1F3F46" style={{ marginTop: 2 }} />
                        <Text style={[Platform.OS === 'web' ? GlobalStyles.blackMediumText : GlobalStyles.blackLightText, { fontSize: 18 }]}>{getTranslation("label_back")}</Text>
                    </View>
                </Pressable>
            </View>
        );
    };

    return (
        <>
            <ScrollView contentContainerStyle={{ flex: 1, position: 'relative', backgroundColor: 'red' }} scrollEnabled={false}>
                <Alert />
                <Stack.Navigator>
                    <Stack.Group
                        screenOptions={{
                            headerShown: false,
                            title: "ScanTickets.de - Einlass App",
                        }}
                    >
                        <Stack.Screen name="VAKeyView" component={VAKeyView} />
                        <Stack.Screen name="AuthKeyView" component={AuthKeyView} />
                        <Stack.Screen name="OverView" component={OverView} options={{
                            headerShown: true,
                            headerTitle: "",
                            headerTintColor: "#1F3F46",
                            headerBackTitleStyle: {
                                fontFamily: "ClearSansRegular",
                                fontSize: 20,
                            },
                            headerBackTitle: getTranslation("label_back"),
                            headerLeft: () => {
                                return <BackButton />;
                            },
                            headerRight: () => {
                                return <Indicator />;
                            },
                        }

                        } />
                        <Stack.Screen name="BarcodeScannerView" component={BarcodeScannerView} options={{
                            headerTitle: "",
                            headerShown: true,
                            headerTintColor: "#1F3F46",
                            headerBackTitleStyle: {
                                fontFamily: "ClearSansRegular",
                                fontSize: 20,
                            },
                            headerBackTitle: getTranslation("label_back"),
                        }} />
                    </Stack.Group>
                    <Stack.Group
                        screenOptions={{
                            presentation: 'modal',
                            headerShown: true,
                            headerTitle: '',
                            headerLeft: (props) => {
                                return (
                                    <BackButton source='modal' />
                                );
                            },
                        }}>
                        <Stack.Screen
                            name="ModalLaw"
                            component={LawPage}
                        />
                        <Stack.Screen
                            name="ModalGuide"
                            component={Guide}
                        />
                        <Stack.Screen
                            name="TicketInformation"
                            component={TicketInformation}
                        />
                        <Stack.Screen
                            name="TicketScanInformation"
                            component={TicketScanInformation}
                        />
                    </Stack.Group>
                </Stack.Navigator>
            </ScrollView>
        </>
    )
}

export default AppNavigator

const styles = StyleSheet.create({
    safeAreaView: {
        flex: 1,
        paddingTop: Platform.OS === "android" ? 25 : 0,
    },
})