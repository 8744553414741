import { Platform, StyleSheet, Text, View } from "react-native";
import React from "react";
import useAdmissionStore from "../stores/AdmissionStore";
import GlobalStyles from "../styles/GlobalStyles";
import { getTranslation } from "../util/languages";
import { useDeviceProperties } from "../hooks/useDeviceProperties";

const Indicator = () => {
  const { admissionType } = useAdmissionStore();
  const { moderateScale } = useDeviceProperties();

  return (
    <View style={styles.container}>
      <Text
        style={[
          GlobalStyles.blackMediumText,
          styles.text,
          { fontSize: moderateScale(16, .12), lineHeight: moderateScale(18, .1) },
        ]}
      >
        {admissionType === "online"
          ? getTranslation("admissionStateOnline")
          : getTranslation("admissionStateOffline")}
      </Text>
      <View
        style={[
          styles.indicator,
          {
            backgroundColor: admissionType === "online" ? "#BEC900" : "#D12A42",
          },
        ]}
      ></View>
    </View>
  );
};

export default Indicator;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    backgroundColor: "#fff",
  },
  indicator: {
    width: 20,
    height: 20,
    borderRadius: 10,
    backgroundColor: "#BEC900",
    marginRight: Platform.OS === "web" ? 30 : 20,
  },
  text: {
    fontFamily: "ClearSansBold",
    marginRight: 10,
    fontSize: 18,
  },
});
