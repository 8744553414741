import AsyncStorage from "@react-native-async-storage/async-storage";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import useTicketStore from "./TicketStore";
import useAlertStore from "./AlertStore";
import { getTranslation } from "../util/languages";

export type AdmissionType = "online" | "offline";

type AdmissionStore = {
  admissionType: AdmissionType;
  switchingState: boolean;
  setAdmissionType: (admissionType: "online" | "offline") => void;
};

const useAdmissionStore = create<AdmissionStore>()(
  devtools(
    persist(
      (set) => ({
        switchingState: false,
        admissionType: "online",
        setAdmissionType: async (admissionType) => {
          set({ switchingState: true, admissionType });

          if (admissionType === 'online') {
            useAlertStore.getState().setAlert({ type: 'success', message: getTranslation('label_online_activated') });
          } else {
            useAlertStore.getState().setAlert({ type: 'success', message: getTranslation('label_offline_activated') });
          }

          set({ switchingState: false });
        },
      }),
      {
        name: "admission-store",
        storage: createJSONStorage(() => AsyncStorage),
      }
    )
  )
);

export default useAdmissionStore;
