import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import GlobalStyles from '../styles/GlobalStyles'

const PlugOnline = () => {
    return (
        <View style={styles.container}>
            <Text style={[GlobalStyles.symbol, styles.text]}></Text>
        </View>
    )
}

export default PlugOnline

const styles = StyleSheet.create({
    container: {
        width: 75,
        height: 75,
        backgroundColor: '#EEEEEE',
        borderRadius: 37,
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        fontSize: 30,
        color: '#323232',
    }
})