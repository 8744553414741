import 'react-native-gesture-handler';
import { Platform, StyleSheet, Text, View } from 'react-native';
import * as SplashScreen from "expo-splash-screen";
import { useEffect, useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import AppNavigator from './components/AppNavigator';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import * as Font from "expo-font";
import * as ScreenOrientation from 'expo-screen-orientation';

import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";
import { useDeviceProperties } from './hooks/useDeviceProperties';

/**
 * This is the entry point of the app.
 * If all neccessary resources are loaded, the user will be redirected to the auth view.
 */

SplashScreen.preventAutoHideAsync();

export default function App() {
    const [fontsLoaded, setFontsLoaded] = useState(false);
    const { width } = useDeviceProperties();

    useEffect(() => {
        async function prepare() {
            if (width < 600) {
                await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
            }
            try {
                Font.loadAsync({
                    ClearSansBold: require('./assets/fonts/ClearSans/ClearSans-Bold.ttf'),
                    ClearSansBoldItalic: require('./assets/fonts/ClearSans/ClearSans-BoldItalic.ttf'),
                    ClearSansItalic: require('./assets/fonts/ClearSans/ClearSans-Italic.ttf'),
                    ClearSansLight: require('./assets/fonts/ClearSans/ClearSans-Light.ttf'),
                    ClearSansMedium: require('./assets/fonts/ClearSans/ClearSans-Medium.ttf'),
                    ClearSansMediumItalic: require('./assets/fonts/ClearSans/ClearSans-MediumItalic.ttf'),
                    ClearSansRegular: require('./assets/fonts/ClearSans/ClearSans-Regular.ttf'),
                    ClearSansThin: require('./assets/fonts/ClearSans/ClearSans-Thin.ttf'),
                    Icomoon: require('./assets/fonts/icomoon/icomoon.ttf'),
                }).then(() => {
                    setFontsLoaded(true);
                });
            } catch (e) {
                console.warn(e);
            }
        }
        prepare();
    }, []);

    if (!fontsLoaded) {
        return null;
    } else {
        SplashScreen.hideAsync();
    }

    return (
        <SafeAreaProvider>
            <NavigationContainer>
                <AppNavigator />
            </NavigationContainer>
        </SafeAreaProvider>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

if (Platform.OS === "web") {
    serviceWorkerRegistration.register();
}
