import AsyncStorage from "@react-native-async-storage/async-storage";
import { create } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";




export type SystemStore = {
    step: number;
    version?: string;
    setStep: (step: number) => void;
    emergencyActive: boolean;
    setEmergencyActive: (maintenanceActive: boolean) => void;
}


const useSystemStore = create<SystemStore>()(
    devtools(
        persist((set) => ({
            step: 1,
            version: '1.0.6',
            setStep: (step: number) => set({ step }),
            emergencyActive: false,
            setEmergencyActive: (emergencyActive: boolean) => set({ emergencyActive: emergencyActive })
        }),
            {
                name: 'system-storage',
                storage: createJSONStorage(() => AsyncStorage),
                partialize: (state) => ({ step: state.step }),
            }
        )
    )
);

export default useSystemStore