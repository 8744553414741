import { StyleSheet, Text, View, Keyboard, Platform } from "react-native";
import React, { useEffect, useRef, useState } from "react";
import { Image } from "expo-image";
import GlobalStyles from "../styles/GlobalStyles";
import CameraInputField from "./CameraInputField";
import FooterBar from "./FooterBar";
import { getTranslation } from "../util/languages";

/**
 * The AuthView component is the first view the user sees when opening the app.
 * It's the view where the user can scan the event key and the auth key to get access to the event.
 *
 * @returns Component for the AuthView
 */

type AuthViewProps = {
  type?: "event" | "auth";
};

const AuthView = ({ type = "event" }: AuthViewProps) => {
  const [headerStyle, setHeaderStyle] = useState({})
  const [contentStyle, setContentStyle] = useState({})
  const [fieldValue, setFieldValue] = useState("")
  const maxLength = 13;
  const cameraFieldRef = useRef(null);

  useEffect(() => {
    const willShowListener = Keyboard.addListener('keyboardWillShow', _keyboardWillShow)
    const didShowListener = Keyboard.addListener('keyboardDidShow', _keyboardWillShow)
    const didHideListener = Keyboard.addListener('keyboardDidHide', _keyboardWillHide)
    const willHideListener = Keyboard.addListener('keyboardWillHide', _keyboardWillHide)
    if (Platform.OS === 'web') {
      cameraFieldRef.current.focusField()
    }
    return () => {
      willShowListener.remove();
      didShowListener.remove();
      didHideListener.remove();
      willHideListener.remove();
    }
  }, []);

  /**
   * Needed to hide the header when the keyboard is shown
   * so the input field stays visible
   */
  const _keyboardWillShow = (e) => {
    setHeaderStyle({ height: 0, flex: 0 })
    setContentStyle({ flex: 1 })
  }
  const _keyboardWillHide = (e) => {
    setHeaderStyle({})
    setContentStyle({})
  }


  const label = {
    event: {
      subline: getTranslation("event_key_needed"),
      placeholder: getTranslation("event_key_placeholder"),
    },
    auth: {
      subline: getTranslation("auth_key_needed"),
      placeholder: getTranslation("auth_key_placeholder"),
    },
  }[type];



  return (
    <View style={{ backgroundColor: "white", flex: 1 }}>
      <View style={[styles.logoWrapper, headerStyle]}>
        <Image
          style={styles.logo}
          source={require("./../assets/scantickets_logo.png")}
          contentFit="contain"
        />
      </View>
      <View style={[styles.scannerWrapper, GlobalStyles.grayContainer, contentStyle]}>
        <View style={styles.top}>
          <Image
            style={styles.scanner}
            source={type === 'event' ? require("./../assets/img/va_scan_emblem.png") : require("./../assets/img/auth_scan_emblem.png")}
            contentFit="contain"
          />
          <Text style={[styles.subline, GlobalStyles.whiteMediumText]}>
            {label.subline}
          </Text>
        </View>
        <View style={styles.cameraInputWrapper}>
          <CameraInputField
            ref={cameraFieldRef}
            value={fieldValue}
            styles={{ width: 291 }}
            height={50}
            placeholder={label.placeholder}
            type={type}
          />
        </View>
      </View>
      <View style={styles.footerBar}>
        <FooterBar />
      </View>
    </View>
  );
};

export default AuthView;

const styles = StyleSheet.create({
  logo: {
    flex: 1,
    maxWidth: 300,
    width: "100%",
  },
  logoWrapper: {
    flex: 2,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  scannerWrapper: {
    flex: 4,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 20,
  },
  footerBar: {
    flex: 1,
  },
  scanner: {
    flex: 1,
    width: "100%",
    maxWidth: 150,
    maxHeight: 150,
    resizeMode: "contain",
  },
  subline: {
    fontSize: 25,
    width: "100%",
    textAlign: "center",
    maxWidth: 260,
  },
  cameraInputWrapper: {
    flex: 1,
    justifyContent: "center",
  },
  top: {
    flex: 2,
    width: "100%",
    alignItems: 'center',
    justifyContent: 'space-around'
  }
});
