import { StyleSheet, Text, View } from "react-native";
import React from "react";
import ColorStripe from "../../components/ColorStripe";
import GlobalStyles from "../../styles/GlobalStyles";
import { getTranslation } from "../../util/languages";
import TicketInfoTable from "../../components/TicketInfoTable";
import { useDeviceProperties } from "../../hooks/useDeviceProperties";

const TicketInformation = () => {
  const { moderateScale } = useDeviceProperties();

  return (
    <>
      <ColorStripe />
      <Text style={[GlobalStyles.blackBoldText, { marginTop: 20, fontSize: moderateScale(18) }]}>
        {getTranslation("label_overview_scanned_tickets")}
      </Text>
      <TicketInfoTable />
    </>
  );
};

export default TicketInformation;

const styles = StyleSheet.create({
  container: {
    padding: 20,
    flex: 1,
    backgroundColor: "white",
    justifyContent: "center",
  },
  headline: {
    fontSize: 30,
    textAlign: "center",
    marginBottom: 20,
  },
  text: {
    fontSize: 16,
    textAlign: "center",
  },
});
