import { StyleSheet, Text, View } from "react-native";
import React from "react";
import ColorStripe from "../../components/ColorStripe";
import { getTranslation } from "../../util/languages";

const Guide = ({ route }) => {
  const { step } = route.params;
  return (
    <>
      <ColorStripe />
      <View style={styles.container}>
        <Text style={styles.headline}>
          {getTranslation(`guide_step_headline`)[step]}
        </Text>
        <Text style={styles.paragraph}>
          {getTranslation(`guide_step_text`)[step]}
        </Text>
      </View>
    </>
  );
};

export default Guide;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    padding: 20,
    justifyContent: "center",
  },
  headline: {
    fontFamily: "ClearSansBold",
    textAlign: "center",
    fontSize: 20,
    marginBottom: 20,
  },
  paragraph: {
    fontFamily: "ClearSansRegular",
    textAlign: "center",
    fontSize: 16,
  },
});
