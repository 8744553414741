import { StyleSheet } from "react-native";

const GlobalStyles = StyleSheet.create({
    grayContainer: {
        backgroundColor: '#28696F',
    },
    darkGrayContainer: {
        backgroundColor: '#1F3F46',
    },
    whiteLightText: {
        color: 'white',
        fontFamily: 'ClearSansLight',
        textAlign: 'center',
    },
    whiteMediumText: {
        color: 'white',
        fontFamily: 'ClearSansMedium',
        textAlign: 'center',
    },
    whiteBoldText: {
        color: 'white',
        fontFamily: 'ClearSansBold',
        textAlign: 'center',
    },
    blackLightText: {
        color: 'black',
        fontFamily: 'ClearSansLight',
        textAlign: 'center',
    },
    blackMediumText: {
        color: 'black',
        textAlign: 'center',
        fontFamily: 'ClearSansMedium'
    },
    redMediumText: {
        color: 'red',
        textAlign: 'center',
        fontFamily: 'ClearSansMedium'
    },
    grayMediumText: {
        color: '#183f46',
        textAlign: 'center',
        fontFamily: 'ClearSansMedium'
    },
    blackBoldText: {
        color: 'black',
        textAlign: 'center',
        fontFamily: 'ClearSansBold'
    },
    grayBoldText: {
        color: '#183f46',
        textAlign: 'center',
        fontFamily: 'ClearSansBold'
    },
    inputStyles: {
        height: 40,
        fontFamily: 'ClearSansMediumItalic',
        fontSize: 18,
        color: 'black',
        paddingVertical: 10,
        paddingHorizontal: 10,
        backgroundColor: 'white',
        flex: 1,
    },
    symbol: {
        fontFamily: 'Icomoon',
        fontSize: 12,
    },
    lightGreyRoundedButton: {
        backgroundColor: '#aaaaaa',
        width: 50,
        height: 50,
        borderRadius: 25,
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        backgroundColor: '#666666',
        height: 50,
        fontFamily: 'ClearSansMedium',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        paddingHorizontal: 20,
    },
    buttonText: {
        fontFamily: 'ClearSansMedium',
        fontSize: 18,
        color: 'white',
    }
});

export default GlobalStyles;