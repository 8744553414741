import { ActivityIndicator, Platform, StyleSheet, Text, View } from "react-native";
import React, { useEffect, useState } from "react";
import LottieView from 'lottie-react-native';
import GlobalStyles from "../styles/GlobalStyles";
import { useDeviceProperties } from "../hooks/useDeviceProperties";
import { getTranslation } from "../util/languages";
import useAdmissionStore from "../stores/AdmissionStore";
import AnimatedLottieView from "lottie-react-native";


type LoadingScreenProps = {
  type?: 'standard' | 'onlinestate' | 'ticketrefresh'
}

const LoadingScreen = ({ type = 'standard' }: LoadingScreenProps) => {
  const { moderateScale } = useDeviceProperties();
  const admissionType = useAdmissionStore(store => store.admissionType);
  const animationRef = React.useRef<AnimatedLottieView | null>(null);

  useEffect(() => {
    if (animationRef.current) {
      setTimeout(() => {
        animationRef.current?.play();
      }, 100);
    }
  }, []);

  let component = {
    standard: <ActivityIndicator color="#1F3F46" />,
    onlinestate: <LottieView
      ref={animationRef}
      source={require('../assets/lottie/connect.json')}
      style={{ width: 170, height: 170 }}
    />,
    ticketrefresh: <LottieView
      ref={animationRef}
      source={require('../assets/lottie/loading.json')}
      style={{ width: 170, height: 170 }}
    />,
  }[type];

  const text = {
    standard: getTranslation('label_loading_standard'),
    onlinestate: `${admissionType == 'online' ? getTranslation('label_switch_to_offline') : getTranslation('label_switch_to_online')}...`,
    ticketrefresh: `${getTranslation('label_loading_ticket_data')}...`,
  }[type];

  if (Platform.OS === 'web') {
    component = <ActivityIndicator color="#1F3F46" size={'large'} style={{ marginBottom: 20 }} />
  };

  return (
    <View style={styles.container}>
      {component}
      <Text style={[GlobalStyles.blackBoldText, { color: '#1F3F46', fontSize: moderateScale(12) }]}>{text}</Text>
    </View>
  );
};

export default LoadingScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
