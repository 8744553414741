import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import GlobalStyles from '../../styles/GlobalStyles'
import ColorStripe from '../../components/ColorStripe'
import { ScrollView } from 'react-native-gesture-handler'
import { getLanguage, getTranslation } from '../../util/languages'
import useTicketStore, { Ticket } from '../../stores/TicketStore'
import 'moment/locale/de';
import moment from 'moment'

const TicketScanInformation = ({ largeScreen }: { largeScreen?: boolean }) => {
    const { lastScannedTicket, scanError } = useTicketStore((state: { lastScannedTicket: Ticket, scanError: string }) => ({
        lastScannedTicket: state.lastScannedTicket,
        scanError: state.scanError,
    }))

    const textColor = largeScreen ? { color: 'white' } : {}

    return (
        <View>
            {
                !largeScreen &&
                <>
                    <ColorStripe />
                    <Text style={[
                        GlobalStyles.blackBoldText, styles.scanInfo
                    ]} >Ticket- & Scaninfo
                    </Text>
                </>
            }
            {<ScrollView style={!largeScreen ? { marginTop: 10, padding: 20 } : {}}>
                {
                    !largeScreen && lastScannedTicket &&
                    <>
                        {
                            lastScannedTicket.prename && lastScannedTicket.surname &&
                            <View style={{ justifyContent: 'space-between', gap: 20, flexDirection: 'row', marginBottom: largeScreen ? 3 : 10 }}>
                                <Text style={[GlobalStyles.grayBoldText, { textAlign: 'left' }, textColor]} >
                                    {getTranslation('label_ticket_buyer')}:
                                </Text>
                                <Text style={[GlobalStyles.grayBoldText, { textAlign: 'right' }, textColor]} >
                                    {`${lastScannedTicket.prename} ${lastScannedTicket.surname}`}
                                </Text>
                            </View>
                        }
                        <View style={{ justifyContent: 'space-between', gap: 20, flexDirection: 'row', marginBottom: largeScreen ? 3 : 10 }}>
                            <Text style={[GlobalStyles.grayBoldText, { textAlign: 'left' }, textColor]} >
                                {getTranslation('label_ticket_type')}:
                            </Text>
                            <Text style={[GlobalStyles.grayBoldText, { textAlign: 'right' }, textColor]} >
                                {lastScannedTicket.type && `${lastScannedTicket.type}`}
                            </Text>
                        </View>
                    </>
                }
                {
                    // Zumindest den Fehler ausgeben, wenn lastScannedTicket nichts enthält
                    scanError && !lastScannedTicket && <View style={{ justifyContent: 'space-between', gap: 20, flexDirection: 'row', marginBottom: largeScreen ? 3 : 10 }}>
                        <Text style={[GlobalStyles.grayBoldText, { textAlign: 'left' }, textColor]} >
                            {scanError}
                        </Text>
                    </View>
                }
                {
                    lastScannedTicket && <>
                        {lastScannedTicket.date_sold && <View style={{ justifyContent: 'space-between', gap: 20, flexDirection: 'row', marginBottom: largeScreen ? 3 : 10 }}>
                            <Text style={[GlobalStyles.grayBoldText, { textAlign: 'right', minWidth: 100 }, textColor]} >
                                {getTranslation('label_bought')}:
                            </Text>
                            <Text style={[GlobalStyles.grayBoldText, { textAlign: 'right' }, textColor]} >
                                {lastScannedTicket.date_sold && moment(lastScannedTicket.date_sold).locale('de').format(`DD.MM.YYYY HH:mm${getLanguage() != 'de' ? ' a' : ''}`)}
                            </Text>
                        </View>}
                        {lastScannedTicket.date_paid && <View style={{ justifyContent: 'space-between', gap: 20, flexDirection: 'row', marginBottom: largeScreen ? 3 : 10 }}>
                            <Text style={[GlobalStyles.grayBoldText, { textAlign: 'right', minWidth: 100 }, textColor]} >
                                {getTranslation('label_payed')}:
                            </Text>
                            <Text style={[GlobalStyles.grayBoldText, { textAlign: 'right' }, textColor]} >
                                {lastScannedTicket.date_paid && moment(lastScannedTicket.date_paid).locale('de').format(`DD.MM.YYYY HH:mm${getLanguage() != 'de' ? ' a' : ''}`)}
                            </Text>
                        </View>}
                        {lastScannedTicket.date_download && <View style={{ justifyContent: 'space-between', gap: 20, flexDirection: 'row', marginBottom: largeScreen ? 3 : 10 }}>
                            <Text style={[GlobalStyles.grayBoldText, { textAlign: 'right', minWidth: 100 }, textColor]} >
                                {getTranslation('label_delivery')}:
                            </Text>
                            <Text style={[GlobalStyles.grayBoldText, { textAlign: 'right' }, textColor]} >
                                {lastScannedTicket.date_download && moment(lastScannedTicket.date_download).locale('de').format(`DD.MM.YYYY HH:mm${getLanguage() != 'de' ? ' a' : ''}`)}
                            </Text>
                        </View>}
                        {lastScannedTicket.date_scan && <View style={{ justifyContent: 'space-between', gap: 20, flexDirection: 'row', marginBottom: largeScreen ? 3 : 10 }}>
                            <Text style={[GlobalStyles.grayBoldText, { textAlign: 'right', minWidth: 100 }, textColor]} >
                                {getTranslation('label_scanned')}:
                            </Text>
                            <Text style={[GlobalStyles.grayBoldText, { textAlign: 'right' }, textColor]} >
                                {
                                    lastScannedTicket.date_scan && moment(lastScannedTicket.date_scan).locale('de').format(`DD.MM.YYYY HH:mm${getLanguage() != 'de' ? ' a' : ''}`)
                                }
                            </Text>
                        </View>}
                        {lastScannedTicket.date_storno && <View style={{ justifyContent: 'space-between', gap: 20, flexDirection: 'row', marginBottom: largeScreen ? 3 : 10 }}>
                            <Text style={[GlobalStyles.grayBoldText, { textAlign: 'right', minWidth: 100 }, textColor]} >
                                {getTranslation('label_cancellation')}:
                            </Text>
                            <Text style={[GlobalStyles.grayBoldText, { textAlign: 'right' }, textColor]} >
                                {lastScannedTicket.date_storno && moment(lastScannedTicket.date_storno).locale('de').format(`DD.MM.YYYY HH:mm${getLanguage() != 'de' ? ' a' : ''}`)}
                            </Text>
                        </View>}
                    </>
                }
            </ScrollView>}
        </View>
    )
}

export default TicketScanInformation

const styles = StyleSheet.create({
    scanInfo: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black',
        textAlign: 'center',
        marginTop: 20,
        marginBottom: 20,
    },
})