import AsyncStorage from "@react-native-async-storage/async-storage";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import methods from "../util/APIKit";
import useEventStore from "./EventStore";
import { getTranslation } from "../util/languages";
import moment from "moment";
import AlertStore from "./AlertStore";
import useAlertStore from "./AlertStore";
import getEnvVars from '../util/environment'
import useAdmissionStore from "./AdmissionStore";



type TicketResponse = {
    success?: string;
    invalid?: number;
    error?: number;
    data?: Ticket[] | Ticket;
}

type ErrorCodes = {
    [key: number]: string;
}

const usability = {
    einmalig: "0",
    mehrfach: "1",
    unbegrenzt: "2"
} as const;


export const errorCodes: ErrorCodes = {
    3012: getTranslation('error_3012'),
    3013: getTranslation('error_3013'),
    3014: getTranslation('error_3014'),
}

export type Ticket = {
    id: string;
    ticket_id: string;
    barcode: string;
    type: string;
    reusability: string;
    company: string | null;
    prename: string | null;
    surname: string | null;
    date_sold: string | null;
    date_paid: string | null;
    date_download: string | null;
    date_scan: string | null;
    date_storno: string | null;
    order_info: string | null;
    pers_info: string | null;
    indi_info: string | null;
    src_id: string | null;
    va_src_id: string | null;
}

type EventsTickets = {
    [key: string]: Ticket[] | Ticket;
}

export type TicketsStore = {
    tickets: EventsTickets[];
    arrEventsTickets: EventsTickets;
    getTickets: (force?: boolean, silent?: boolean) => Promise<boolean>;
    getTicketCountTotal: () => number;
    isLoading: boolean;
    getTicketCountScanned: () => number;
    processValidation: (barcode: string, ticket: Ticket, set: ({ }) => void) => Promise<void>;
    ticketTypeInformation: TicketInformation[];
    barcode: string;
    setBarcode: (barcode: string) => void;
    scanError: string | null;
    barcodeValidationSuccess: boolean | null;
    resetBarcodeValidationSuccess: () => void;
    lastScannedTicket: Ticket | null;
    validateBarcode: (refreshed?: boolean) => Promise<void>;
    syncAllTicketsOfEventToServer: () => Promise<boolean>;
}

export type TicketInformation = {
    type: string;
    count: number;
    countScanned: number;
}



/**
 * 
 * ACTIONS
 *
 */


// Gibt alle gescannten Tickets eines Events zurück
const getAllScannedTicketsOfEvent = (eventKey: string) => {
    if (useTicketStore.getState().arrEventsTickets[eventKey] == undefined) return []

    const tickets = useTicketStore.getState().arrEventsTickets[eventKey] as Ticket[];
    if (tickets.length == 0) {
        return []
    }
    return tickets.filter((ticket: Ticket) => ticket.date_scan);
}


// Funktion um ein einzelnes Ticket zu updaten
const updateSingleTicketInLocalStore = (barcode: string, set: ({ }) => void) => {
    const eventKey = useEventStore.getState().event_key;
    const tickets = useTicketStore.getState().arrEventsTickets[useEventStore.getState().event_key] as Ticket[];

    if (tickets.length == 0) {
        console.log('👉 Script TicketStore.ts line 93 Keine Tickets geladen');
        useAlertStore.getState().setAlert({ message: getTranslation('error_ticket_6006'), type: 'error' })
        return
    }

    // Ticketindex finden
    const index = tickets.findIndex((ticket: Ticket) => {
        return ticket.barcode == barcode
    });

    if (index == -1) {
        console.log('👉 Script TicketStore.ts line 104 Ticket nicht gefunden');
        useAlertStore.getState().setAlert({ message: getTranslation('error_ticket_4006'), type: 'error' })
        return
    }

    tickets[index].date_scan = moment().format('YYYY-MM-DD HH:mm:ss');

    set((state: TicketsStore) => ({ arrEventsTickets: { ...state.arrEventsTickets, [eventKey]: tickets } }))
}

// Ticketdaten eines einzelnen Tickets vom Server holen
const getSingleTicketDataFromServer = async (id: string, set: ({ }) => void) => {
    const { EXPO_PUBLIC_URL_REQUEST_TICKET_DATA } = getEnvVars()
    // Wenn Offlinemodus, dann NULL return
    if (useAdmissionStore.getState().admissionType === 'offline') return null;

    // Querystring zusammenbauen
    const query = {
        va_key: useEventStore.getState().event_key,
        auth_key: useEventStore.getState().auth_key,
        id: id,
        method: 'read_single'
    }
    try {
        // Request an den Server
        const responseData = await methods.get<TicketResponse>({ url: EXPO_PUBLIC_URL_REQUEST_TICKET_DATA, query })
        // Wenn die Antwort vom Server kommt
        if (responseData.success && responseData.success == 'true') {
            const res = responseData.data as Ticket
            return res;
        } else if (responseData.invalid) {
            set({ isLoading: false });
            alert(errorCodes[responseData.invalid])
            return null;
            // Fehler passiert
        } else if (responseData.error) {
            set({ isLoading: false });
            alert(errorCodes[responseData.error])
            return null;
        }
    } catch (error) {
        useAlertStore.getState().setAlert({ message: getTranslation('label_server_connect_failed'), type: 'warning' })
        return null;
    }
}


// Ticketdaten eines einzelnen Tickets auf dem Server updaten
const updateSingleTicketDataOnServer = async (data: { id: string, date_scan: string }, set: ({ }) => void) => {
    const { EXPO_PUBLIC_URL_REQUEST_TICKET_DATA } = getEnvVars()
    if (useAdmissionStore.getState().admissionType === 'offline') return false
    // Querystring zusammenbauen
    const query = {
        va_key: useEventStore.getState().event_key,
        auth_key: useEventStore.getState().auth_key,
        method: 'update'
    }
    try {
        // Request an den Server
        const responseData = await methods.post<TicketResponse>({ url: EXPO_PUBLIC_URL_REQUEST_TICKET_DATA, query, data })
        // Wenn die Antwort vom Server kommt
        if (responseData.success && responseData.success == 'true') {
            return true;
        } else if (responseData.invalid) {
            set({ isLoading: false });
            AlertStore.getState().setAlert({ message: errorCodes[responseData.invalid], type: 'info' })
            return false;
            // Fehler passiert
        } else if (responseData.error) {
            set({ isLoading: false });
            AlertStore.getState().setAlert({ message: errorCodes[responseData.error], type: 'error' })
            return false;
        }
    } catch (error) {
        AlertStore.getState().setAlert({ message: getTranslation('error_common'), type: 'error' })
        console.warn('👉 Script TicketStore.ts line 199 ', error);
        return false;
    }
}

// Funktion um die Ticketinformationen pro Typ zu setzen
const setTicketsTypeInformation = (tickets: Ticket[], set: ({ }) => void) => {
    const types = tickets.map((ticket: Ticket) => ticket.type);
    const uniqueTypes = [...new Set(types)];

    // Get count of each type
    const typeCount = uniqueTypes.map<TicketInformation>((type: string) => {
        return {
            type,
            count: tickets.filter((ticket: Ticket) => ticket.type == type).length,
            countScanned: tickets.filter((ticket: Ticket) => ticket.type == type && ticket.date_scan).length
        }
    })
    set({ ticketTypeInformation: typeCount });
}


const useTicketStore = create<TicketsStore>()(
    devtools(
        persist((set, get) => ({
            tickets: [],
            barcode: '',

            // Synchronisiert alle gescannten Tickets eines Events mit dem Server
            syncAllTicketsOfEventToServer: async () => {
                const { EXPO_PUBLIC_URL_REQUEST_TICKET_DATA } = getEnvVars()
                const relevantTix = getAllScannedTicketsOfEvent(useEventStore.getState().event_key);
                if (relevantTix.length > 0) {
                    try {
                        const res = await methods.post<TicketResponse>({
                            url: EXPO_PUBLIC_URL_REQUEST_TICKET_DATA,
                            query: {
                                va_key: useEventStore.getState().event_key,
                                auth_key: useEventStore.getState().auth_key,
                                method: 'offline_sync'
                            },
                            data: relevantTix
                        })
                        if (res.success && res.success == 'true') {
                            return true
                        } else {
                            useAlertStore.getState().setAlert({ message: getTranslation('label_server_connect_failed'), type: 'error' });
                            return false
                        }
                    } catch (error) {
                        useAlertStore.getState().setAlert({ message: getTranslation('label_server_connect_failed'), type: 'error' });
                        return false
                    }
                }
                return true
            },
            scanError: null,
            barcodeValidationSuccess: null,
            lastScannedTicket: null as Ticket | null,
            arrEventsTickets: {},

            // Zurücksetzen des Barcode-Validierungs-Status
            resetBarcodeValidationSuccess: () => {
                set({ barcodeValidationSuccess: null, lastScannedTicket: null, scanError: null });
            },
            // Setzt den Barcode bspw. nach dem Scannen
            setBarcode: (barcode: string) => {
                set({ barcode });
            },

            // Gesamtanzahl der Tickets
            getTicketCountTotal: () => {
                return get().arrEventsTickets[useEventStore.getState().event_key]?.length || 0;
            },

            // Anzahl aller gescannten Tickets
            getTicketCountScanned: () => {
                let len = 0;
                const tix = get().arrEventsTickets[useEventStore.getState().event_key] as Ticket[];
                tix?.forEach((ticket: Ticket) => {
                    if (ticket.date_scan) {
                        len++;
                    }
                })
                return len;
            },

            // Validierungsprozess eines einzelnen Tickets
            processValidation: async (barcode: string, ticket: Ticket, set: ({ }) => void) => {
                const serverTik = await getSingleTicketDataFromServer(ticket.id, set);
                let ticketToProof = null as Ticket | null;
                let ticketWasValid = true;

                ticketToProof = ticket;
                // Hat er ein Ticket vom Server bekommen?
                if (serverTik) {
                    ticketToProof = serverTik;
                }
                set({ lastScannedTicket: ticketToProof })

                // Das Ticket wurde bereits gescannt
                if (ticketToProof.date_scan) {
                    // Wenn nur einmaliges Scannen erlaubt ist
                    if (ticketToProof.reusability == usability.einmalig) {
                        if (ticketToProof.date_scan && moment(ticketToProof.date_scan).isSame(moment(), 'day')) {
                            set({ barcodeValidationSuccess: false, scanError: getTranslation('error_ticket_6009').replace('Ticket ', getTranslation('error_today_word')) })
                        } else {
                            set({ barcodeValidationSuccess: false, scanError: getTranslation('error_ticket_6009') })
                        }
                        ticketWasValid = false;
                        // Wenn EINMAL Scannen PRO TAG erlaubt ist
                    } else if (ticketToProof.reusability == usability.mehrfach) {
                        if (ticketToProof.date_scan && moment(ticketToProof.date_scan).isSame(moment(), 'day')) {
                            set({ barcodeValidationSuccess: false, scanError: getTranslation('error_ticket_6009').replace('Ticket ', getTranslation('error_today_word')) })
                            ticketWasValid = false;
                        } else {
                            set({ barcodeValidationSuccess: true })
                        }

                        // Wenn unbegrenzt Scannen erlaubt ist
                    } else if (ticketToProof.reusability == usability.unbegrenzt) {
                        set({ barcodeValidationSuccess: true })
                    }

                    // Wenn das Ticket storniert wurde
                } else if (ticketToProof.date_storno) {
                    console.log('👉 Script TicketStore.ts line 87 ', 'Wurde storniert');
                    set({ barcodeValidationSuccess: false, scanError: getTranslation('error_ticket_6008') })
                    ticketWasValid = false;

                    // Wenn das Ticket nicht bezahlt wurde
                } else if (ticketToProof.date_paid == null) {
                    console.log('👉 Script TicketStore.ts line 87 ', 'Ticket wurde nicht bezahlt');
                    set({ barcodeValidationSuccess: false, scanError: getTranslation('error_ticket_6007') })
                    ticketWasValid = false;
                }

                // Wennn das Ticket gültig ist, dann mit Einlassdatum updaten
                if (ticketWasValid == true) {
                    set({ barcodeValidationSuccess: true })
                    updateSingleTicketInLocalStore(barcode, set);
                    const resTicketsUpdate = await updateSingleTicketDataOnServer({ id: ticketToProof.id, date_scan: moment().format('YYYY-MM-DD HH:mm:ss') }, set);
                    if (!resTicketsUpdate && useAdmissionStore.getState().admissionType == 'online') {
                        useAdmissionStore.getState().setAdmissionType('offline')
                    }
                }

                // Wenn Onlineeinlass Ticketsstore updaten
                if (useAdmissionStore.getState().admissionType == 'online') {
                    get().getTickets(true, true);
                }

                // get().setBarcode('');
            },

            // Stößt den Validierungsprozess an
            validateBarcode: async (refreshed = false) => {
                get().resetBarcodeValidationSuccess();
                const barcode = get().barcode;

                // Barcode vorhanden?
                if (barcode.length > 0) {
                    // Tickets zur Veranstaltung aus dem lokalen Speicher holen
                    let tickets = get().arrEventsTickets[useEventStore.getState().event_key] as Ticket[];
                    if (tickets.length > 0) {

                        // Ticket mit dem Barcode finden
                        const ticket = tickets.filter((ticket: Ticket) => {
                            return ticket.barcode == barcode
                        });

                        // Ticket gefunden -> validieren?
                        if (ticket.length > 0) {
                            get().processValidation(barcode, ticket[0], set);
                            setTicketsTypeInformation(tickets as Ticket[] || [], set);
                        } else {

                            // Ticket nicht gefunden

                            // Wenn das Ticket beim ersten Durchlauf nicht gefunden wurde, dann nochmal Tickets vom Server holen und nochmal probieren (nur wenn Online-Modus)
                            // Für den Fall, dass ein Ticket erst nach dem Laden der Tickets gekauft wurde
                            if (refreshed || useAdmissionStore.getState().admissionType == 'offline') {
                                set({ scanError: getTranslation('error_ticket_4006'), barcodeValidationSuccess: false });
                                return
                            } else {
                                await get().getTickets(true, true);
                                get().validateBarcode(true);
                            }
                        }
                    } else {

                        // Es wurden keine Tickets zur Veranstaltung geladen
                        set({ scanError: getTranslation('error_ticket_6006'), barcodeValidationSuccess: false });
                        return

                    }
                }

            },
            ticketTypeInformation: [],
            isLoading: false,

            // Laden der Tickets vom Server
            getTickets: async (force = false, silent = false) => {
                const { EXPO_PUBLIC_URL_REQUEST_TICKET_DATA } = getEnvVars()
                // Loading setzen
                if (!silent) {
                    set({ isLoading: true });
                }

                // Querystring zusammenbauen
                const query = {
                    va_key: useEventStore.getState().event_key,
                    auth_key: useEventStore.getState().auth_key,
                    method: 'read'
                }
                try {
                    // Tickets im Offline-Modus aus dem Gerätespeicher holen
                    if (useAdmissionStore.getState().admissionType == 'offline' && force == false) {
                        set({ isLoading: false });
                        const tickets = useTicketStore.getState().arrEventsTickets[useEventStore.getState().event_key] as Ticket[];
                        setTicketsTypeInformation(tickets, set);
                        return true;
                    }

                    // Request an den Server
                    const responseData = await methods.get<TicketResponse>({ url: EXPO_PUBLIC_URL_REQUEST_TICKET_DATA, query })
                    // Wenn die Antwort vom Server kommt
                    if (responseData.success && responseData.success == 'true') {
                        const ticket: EventsTickets = {
                            [useEventStore.getState().event_key]: responseData.data || []
                        };
                        // Wenn Offline-Modus, dann nur die Tickets updaten und nicht den ganzen Store ersetzen
                        if (useAdmissionStore.getState().admissionType == 'offline') {
                            set(state => ({ isLoading: false, arrEventsTickets: { ...state.arrEventsTickets, ...ticket } }));
                        } else {
                            set({ isLoading: false, arrEventsTickets: { ...ticket } });
                        }
                        if (!silent) {
                            AlertStore.getState().setAlert({ message: getTranslation('label_ticket_refresh_success'), type: 'success' })
                        }
                        setTicketsTypeInformation(responseData.data as Ticket[] || [], set);
                        return true;
                    } else if (responseData.invalid) {
                        set({ isLoading: false });
                        if (!silent) {
                            AlertStore.getState().setAlert({ message: errorCodes[responseData.invalid], type: 'error' })
                        }
                        return false;
                        // Fehler passiert
                    } else if (responseData.error) {
                        set({ isLoading: false });
                        if (!silent) {
                            AlertStore.getState().setAlert({ message: errorCodes[responseData.error], type: 'error' })
                        }
                        return false;
                    }
                } catch (error) {
                    if (!silent) {
                        AlertStore.getState().setAlert({ message: getTranslation('label_server_connect_failed'), type: 'warning' })
                    }
                }
                set({ isLoading: false });
                return false;
            }
        }),
            {
                name: 'ticket-storage',
                // Festlegen, welche Teile des Stores persistent gespeichert werden sollen
                partialize: (state) => ({ ticketTypeInformation: state.ticketTypeInformation, arrEventsTickets: state.arrEventsTickets }),
                storage: createJSONStorage(() => AsyncStorage)
            }
        )
    )
);

export default useTicketStore;