import { StyleSheet, Text, View } from "react-native";
import AuthView from "../../components/AuthView";
import LoadingScreen from "../../components/LoadingScreen";
import { useAuthView } from "../../hooks/useAuthView";
import STButton from "../../components/STButton";
import useSTStorage from "../../hooks/useStStorage";

const AuthKeyView = () => {
  const { isLoading } = useAuthView("auth");
  const { dumpAllDataFromStorage } = useSTStorage();

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <AuthView type="auth" />
    </>
  );
};

export default AuthKeyView;

const styles = StyleSheet.create({});
