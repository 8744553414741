import {
  StyleSheet,
  Text,
  TextInput,
  View,
  ViewStyle,
  TextStyle,
  Keyboard,
  Pressable,
  Platform,
} from "react-native";
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import GlobalStyles from "../styles/GlobalStyles";
import useEventStore from "../stores/EventStore";
import useTicketStore, { TicketsStore } from "../stores/TicketStore";
import { ScanFieldType } from "../hooks/useConfig";
import { useDeviceProperties } from "../hooks/useDeviceProperties";
import { useNavigation } from "@react-navigation/native";
import useSystemStore from "../stores/SystemStore";

/**
 * The CameraInputField component is a custom input field with a camera icon on the right side.
 * By clicking the camera icon the user can scan a QR code.
 */

interface CameraInputProps {
  styles?: ViewStyle;
  placeholder?: string;
  inputStyles?: TextStyle;
  height?: number;
  disabled?: boolean;
  type: ScanFieldType;
  value?: string;
}

const maxLength = 13;

const CameraInputField = forwardRef(
  ({
    styles,
    inputStyles = {},
    placeholder = "Input",
    height = 40,
    disabled = false,
    type = "event",
  }: CameraInputProps, ref) => {
    const navigation = useNavigation();
    const [fieldValue, setFieldValue] = useState("");

    const { moderateScale } = useDeviceProperties();

    const { setBarcode, barcode } = useTicketStore((state: TicketsStore) => ({
      setBarcode: state.setBarcode,
      barcode: state.barcode,
    }));

    const inputRef = useRef<TextInput>(null);

    // Übergeordnete Kompontente kann auf diese Funktion zugreifen (siehe AuthView.tsx useEffect)
    useImperativeHandle(ref, () => ({
      focusField: () => {
        inputRef.current?.focus();
      }
    }));

    const { setEventKey, setAuthKey, eventKey, authKey } = useEventStore(
      (state) => ({
        setEventKey: state.setEventKey,
        setAuthKey: state.setAuthKey,
        eventKey: state.event_key,
        authKey: state.auth_key,
      })
    );

    // Feld leeren, wenn Barcode resettet wurde
    useEffect(() => {
      if (type === 'ticket') {
        setFieldValue(barcode);
      }
    }, [barcode]);

    /**
     * Handles the input field change
     * @param value The value of the input field
     */
    const changeHandler = (value: string) => {
      // Achtung: Keyboard.dismiss wirft dieses event auch und muss auf diese Weise ignoriert werden
      if (!inputRef.current?.isFocused()) {
        return;
      }
      // Schwenk auf Notfallsystem
      if (/emergency/.test(value)) {
        useSystemStore.getState().setEmergencyActive(true);
        value = value.replace(/emergency/, '');
      }

      value = value.replace(/[^\da-z]/gi, "");
      if (value.length > maxLength) {
        // Da der Barcode nach dem Scan nicht automatisch gelöscht wird damit er weiterhin angezeigt werden kann, muss das hier gemacht werden
        // Wenn die Länge größer als 13 ist, wird der Barcode zurückgesetzt und der Rest der Eingabe in das Feld geschrieben
        setBarcode('');
        value = value.slice(maxLength);
      }
      setFieldValue(value);
      // Max length reached, save value an go to next screen
      if (value.length === maxLength) {
        Keyboard.dismiss();
      }
      setTimeout(() => {
        switch (type) {
          case "event":
            if (value.length === maxLength) {
              setEventKey(value);
            }
            break;
          case "auth":
            if (value.length === maxLength) {
              setAuthKey(value);
            }
            break;
          case "ticket":
            if (value.length === maxLength) {
              setBarcode(value);
              inputRef.current?.focus();
            }
            break;
        }
      }, 100);
    };

    return (
      <View style={[internalStyles.container, styles, { height }]}>
        <TextInput
          style={[GlobalStyles.inputStyles, { height }, inputStyles]}
          editable={!disabled}
          placeholderTextColor={"black"}
          placeholder={placeholder}
          ref={inputRef}
          value={fieldValue}
          autoCapitalize="none"
          onChange={(e) => changeHandler(e.nativeEvent.text)}
        />
        <Pressable style={({ pressed }) => [internalStyles.clearField, { height, opacity: pressed ? .5 : 1 }]} onPress={() => setFieldValue('')} >
          <Text style={[GlobalStyles.symbol, { color: 'rgba(0, 0, 0, .3)', fontSize: moderateScale(15, .05) }]}></Text>
        </Pressable>
        {Platform.OS != 'web' && <Pressable
          onPress={() =>
            navigation.navigate("BarcodeScannerView", { type })
          }
          style={({ pressed }) => ({ opacity: pressed ? 0.5 : 1 })}
        >
          <View
            style={[
              internalStyles.symbolWrapper,
              { height: height + 1, width: height + 1 },
              GlobalStyles.darkGrayContainer,
            ]}
          >
            <Text
              style={[
                GlobalStyles.symbol,
                internalStyles.symbol,
                { fontSize: height / 2.2, lineHeight: height / 2.2, height: height / 2.2 + 1 }
              ]}
            >
              
            </Text>
          </View>
        </Pressable>}
      </View>
    );
  }
);

export default CameraInputField;

const internalStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    borderWidth: 0,
    backgroundColor: "white",
    borderRadius: 3,
    marginBottom: 10,
    overflow: "hidden",
    minWidth: 150,
  },
  symbolWrapper: {
    height: 40,
    width: 40,
    justifyContent: "center",
    padding: 10,
    alignSelf: "flex-end",
  },
  symbol: {
    alignSelf: "center",
    color: "white",
    height: 12,
  },
  clearField: {
    height: 40,
    width: 40,
    justifyContent: "center",
    alignItems: 'center',
  }
});
