import { Platform, Pressable, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import GlobalStyles from "../styles/GlobalStyles";
import ColorStripe from "./ColorStripe";
import useSystemStore from "../stores/SystemStore";
import { useNavigation } from "@react-navigation/native";
import { getTranslation } from "../util/languages";
import { useDeviceProperties } from "../hooks/useDeviceProperties";

const FooterBar = () => {
  const navigation = useNavigation();


  const showGuide = () => {
    navigation.navigate(`ModalGuide`, { step: useSystemStore.getState().step });
  };

  const showLaw = () => {
    navigation.navigate("ModalLaw");
  };

  let additionalStyles = null
  if (Platform.OS === 'web') {
    additionalStyles = { flex: .2 };
  }

  return (
    <>
      <ColorStripe />
      <View style={[styles.container, additionalStyles]}>
        <TouchableOpacity
          onPress={showGuide}
          style={[GlobalStyles.lightGreyRoundedButton, styles.button]}
        >
          <Text style={[GlobalStyles.symbol, styles.symbol]}></Text>
        </TouchableOpacity>
        {useSystemStore.getState().emergencyActive && <Text style={[GlobalStyles.redMediumText, styles.text]}>
          {getTranslation("label_emergency_system")}
        </Text>}
        {/* Installationbutton für die PWA */}
        <View>
          {Platform.OS === 'web' && <Pressable id="install-btn" style={[GlobalStyles.button]}>
            <Text style={[GlobalStyles.blackMediumText, styles.installBtn]}>{getTranslation('label_install_pwa')}</Text>
          </Pressable>}
          {__DEV__ && <Text style={[GlobalStyles.blackMediumText, styles.text]}>Version {useSystemStore.getState().version}</Text>}
        </View>
        <TouchableOpacity
          onPress={showLaw}
          style={[GlobalStyles.lightGreyRoundedButton, styles.button]}
        >
          <Text style={[GlobalStyles.symbol, styles.symbol]}></Text>
        </TouchableOpacity>
      </View>
    </>
  );
};

export default FooterBar;

const styles = StyleSheet.create({
  installBtn: {
    color: 'white',
  },
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    borderWidth: 0,
    backgroundColor: "white",
    padding: 20,
  },
  symbol: {
    alignSelf: "center",
    fontSize: 30,
    color: "white",
  },
  text: {
    paddingHorizontal: 10
  },
  button: {},
});
