import { StyleSheet, Text, View, ScrollView, TextBase } from 'react-native'
import React, { useEffect, useState } from 'react'
import GlobalStyles from '../styles/GlobalStyles';
import { useDeviceProperties } from '../hooks/useDeviceProperties';
import { getLanguage, getTranslation } from '../util/languages';
import STButton from './STButton';
import useTicketStore, { TicketsStore } from '../stores/TicketStore';
import TicketScanInformation from '../Screens/modal/TicketScanInformation';
import { useNavigation } from '@react-navigation/native';

/**
 * Component to display the result of a ticket scan
 * 
 * @author A.Moerke (oe-konzept)
 * @version 1.0
 * 
 * @changed 2023-09-22 14:29:49
 */

type TicketResultType = 'success' | 'error' | 'none';

const TicketResultContainer = () => {
    const { largeScreen, moderateScale, verticalScale } = useDeviceProperties();
    const navigation = useNavigation();
    const [type, setType] = useState<TicketResultType>('none')
    const { barcodeValidationSuccess, scanError, lastScannedTicket } = useTicketStore((state: TicketsStore) => ({
        barcodeValidationSuccess: state.barcodeValidationSuccess,
        scanError: state.scanError,
        lastScannedTicket: state.lastScannedTicket,
    }))

    useEffect(() => {
        if (barcodeValidationSuccess === null) {
            setType('none')
        } else if (barcodeValidationSuccess && !scanError) {
            setType('success')
        } else if (barcodeValidationSuccess === false) {
            setType('error')
        }
    }, [barcodeValidationSuccess, scanError])

    const symbolText = {
        success: '', // Häckchen
        error: '', // Kreuz
        none: '', // Leer
    }[type]

    const componentColor = {
        success: '#BEC900',
        error: '#D22A43',
        none: 'transparent',
    }[type]


    // Personalisierungs- und Individualisierungsdaten
    let personalInfos = null
    if (lastScannedTicket && lastScannedTicket.pers_info) {
        personalInfos = JSON.parse(lastScannedTicket.pers_info)
    }
    let indiInfos = null
    if (lastScannedTicket && lastScannedTicket.indi_info) {
        indiInfos = JSON.parse(lastScannedTicket.indi_info)
    }

    let infoTextComponent = null

    const mobileBoxStyles = [internalStyles.innerContainer, { backgroundColor: 'white', padding: 20, marginBottom: 5 }];

    // Mobile
    infoTextComponent = <>
        {lastScannedTicket &&
            <>
                {/* Tickettyp */}
                <View style={mobileBoxStyles}>
                    <Text style={[GlobalStyles.blackLightText, { textAlign: 'left' }]}>{getTranslation('label_ticket_type')}: </Text>
                    <Text style={[GlobalStyles.blackBoldText, { textAlign: 'left', fontSize: moderateScale(16) }]}>{lastScannedTicket.type}</Text>
                </View>
                {lastScannedTicket.prename && lastScannedTicket.surname && !personalInfos ?
                    <View style={mobileBoxStyles}>
                        <Text style={[GlobalStyles.blackBoldText, { textAlign: 'left' }]}>
                            {getTranslation('label_ticket_buyer')}:
                            <Text style={GlobalStyles.blackLightText} >{`${lastScannedTicket.prename} ${lastScannedTicket.surname}`}</Text>
                        </Text>
                    </View>
                    : null
                }
                {
                    lastScannedTicket && personalInfos &&
                    <View style={mobileBoxStyles}>
                        {
                            personalInfos.keys.map((key: string, index: number) => {
                                return (
                                    <View key={index} style={{ alignItems: 'baseline' }}>
                                        <Text style={[GlobalStyles.blackLightText, { fontSize: moderateScale(14, .2), textAlign: 'left', marginTop: index > 0 ? 10 : 0 }]}>{key}:</Text>
                                        <Text style={[GlobalStyles.blackBoldText, { fontSize: moderateScale(16, .2), textAlign: 'left' }]}>{personalInfos.values[index]}</Text>
                                    </View>
                                )
                            })
                        }
                    </View>
                }
                {
                    lastScannedTicket && indiInfos &&
                    <View style={mobileBoxStyles}>
                        {
                            indiInfos.keys.map((key: string, index: number) => {
                                return (
                                    <View key={index} style={{ alignItems: 'baseline' }}>
                                        <Text style={[GlobalStyles.blackLightText, { fontSize: moderateScale(14, .2), textAlign: 'left', marginTop: index > 0 ? 10 : 0 }]}>{key}:</Text>
                                        <Text style={[GlobalStyles.blackBoldText, { fontSize: moderateScale(16, .2), textAlign: 'left' }]}>{indiInfos.values[index]}</Text>
                                    </View>
                                )
                            })
                        }
                    </View>
                }
            </>
        }
    </>
    // Desktop / Tablet
    if (largeScreen) {
        infoTextComponent = <TicketScanInformation largeScreen />
    }




    const component = {
        'large': (
            <View style={{ flex: 1, justifyContent: 'space-between', gap: 15 }}>
                {/* Valide oder nicht, gescannt, storniert, gekauft etc. */}
                <View style={{ backgroundColor: componentColor }}>
                    <View style={[internalStyles.container, { paddingTop: verticalScale(60), paddingBottom: verticalScale(40), backgroundColor: componentColor }]}>
                        <View style={[internalStyles.innerContainer, { justifyContent: 'center', alignItems: 'center', gap: 25 }]}>
                            <Text style={[
                                GlobalStyles.symbol,
                                internalStyles.symbol,
                                { fontSize: moderateScale(45, .2) }
                            ]}>{symbolText}</Text>
                            <Text style={[
                                GlobalStyles.blackBoldText,
                                internalStyles.statusText
                            ]} >{getTranslation(type == 'error' ? 'label_invalid' : 'label_valid')}</Text>
                        </View>
                        <View style={[internalStyles.innerContainer, { justifyContent: 'center' }]}>
                            <Text style={internalStyles.infoText}>{infoTextComponent}</Text>
                        </View>
                    </View>
                    {
                        scanError &&
                        <View style={[internalStyles.pill, { margin: 15 }]}>
                            <Text style={[GlobalStyles.whiteBoldText, { fontSize: moderateScale(14, .2), textAlign: 'center', color: componentColor }]}>{scanError}</Text>
                        </View>
                    }
                </View>

                <View style={{ gap: 10 }}>
                    {/* Tickettyp */}
                    <View style={[{ backgroundColor: "white", padding: 20, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: 4 }]}>
                        <Text style={[GlobalStyles.blackLightText, { fontSize: moderateScale(14, .2) }]} >{getTranslation('label_ticket_type')}:</Text>
                        <Text style={[GlobalStyles.blackBoldText, { fontSize: moderateScale(20, .2) }]} >{lastScannedTicket?.type}</Text>
                    </View>

                    {/* Personalisierungs- und Individualisierungsdaten */}
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'flex-start', paddingBottom: 20, justifyContent: 'space-between' }}>
                        {
                            lastScannedTicket && lastScannedTicket.prename && lastScannedTicket.surname && lastScannedTicket.order_info == '' &&
                            <View style={[internalStyles.innerContainer, { justifyContent: 'space-around', padding: 75, backgroundColor: 'white', borderRadius: 10, alignItems: 'baseline' }]}>
                                <Text style={GlobalStyles.whiteBoldText} >{getTranslation('label_ticket_buyer')}</Text>
                                <Text style={GlobalStyles.whiteMediumText} >{`${lastScannedTicket.prename} ${lastScannedTicket.surname}`}</Text>
                            </View>
                        }
                        {
                            lastScannedTicket && personalInfos &&
                            <View style={[internalStyles.innerContainer, { padding: 75, backgroundColor: 'white', borderRadius: 10, flex: indiInfos ? .495 : 1 }]}>
                                {
                                    personalInfos.keys.map((key: string, index: number) => {
                                        return (
                                            <View key={index} style={{ alignItems: indiInfos ? 'baseline' : 'center', padding: 20 }}>
                                                <Text style={[GlobalStyles.blackLightText, { fontSize: moderateScale(14, .2), textAlign: indiInfos ? 'left' : 'center' }]}>{key}:</Text>
                                                <Text style={[GlobalStyles.blackBoldText, { fontSize: moderateScale(16, .2), textAlign: indiInfos ? 'left' : 'center' }]}>{personalInfos.values[index]}</Text>
                                            </View>
                                        )
                                    })
                                }
                            </View>
                        }
                        {
                            lastScannedTicket && indiInfos &&
                            <View style={[internalStyles.innerContainer, { padding: 75, backgroundColor: 'white', borderRadius: 10, flex: .495 }]}>
                                {
                                    indiInfos.keys.map((key: string, index: number) => {
                                        return (
                                            <View key={index} style={{ alignItems: 'baseline', padding: 20 }}>
                                                <Text style={[GlobalStyles.blackLightText, { fontSize: moderateScale(14, .2), textAlign: 'left' }]}>{key}:</Text>
                                                <Text style={[GlobalStyles.blackBoldText, { fontSize: moderateScale(16, .2), textAlign: 'left' }]}>{indiInfos.values[index]}</Text>
                                            </View>
                                        )
                                    })
                                }
                            </View>
                        }
                    </View>

                </View>

            </View>
        ),

        'small': (
            <View style={internalStyles.mobileContainer}>
                {/* Titlebar */}
                <View style={[{ backgroundColor: componentColor }]}>
                    <View style={[internalStyles.titleContainer, { backgroundColor: componentColor }]}>
                        <View style={{ flexDirection: 'row', gap: 10 }}>
                            <Text style={[
                                GlobalStyles.symbol,
                                internalStyles.symbol,
                                { fontSize: moderateScale(30, .2), lineHeight: moderateScale(30, .2) }]}>{symbolText}</Text>
                            <Text style={[
                                GlobalStyles.blackBoldText,
                                internalStyles.statusText,
                                { textAlign: 'left' }]} >{getTranslation(type == 'error' ? 'label_invalid' : 'label_valid')}</Text>
                        </View>
                        <View>
                            <STButton
                                title=""
                                onPress={() => { navigation.navigate('TicketScanInformation') }}
                                labelStyles={[GlobalStyles.symbol, { color: componentColor, fontSize: 20, lineHeight: 20 }]}
                                buttonStyles={[internalStyles.buttonStyle]}
                            />
                        </View>
                    </View>
                    {
                        scanError &&
                        <View style={[internalStyles.pill]}>
                            <Text style={[GlobalStyles.whiteBoldText, { fontSize: moderateScale(14), textAlign: 'center', color: componentColor }]}>{scanError}</Text>
                        </View>
                    }
                </View>
                {/* Infos */}
                <View>
                    {infoTextComponent}
                </View>
            </View>
        )
    }[largeScreen ? 'large' : 'small']

    if (type == 'none') return null

    return component
}

export default TicketResultContainer

const internalStyles = StyleSheet.create({
    container: {
        flex: 1,
        borderRadius: 4,
        padding: 10,
        flexDirection: 'row',
        // maxHeight: 180,
    },
    buttonStyle: {
        padding: 0,
        backgroundColor: "white",
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        width: 'auto',
    },
    infoContainer: {
        padding: 10,
    },
    pill: {
        borderRadius: 20,
        backgroundColor: 'white',
        padding: 7,
        margin: 10,
    },
    mobileContainer: {
        flex: 1,
    },
    titleContainer: {
        height: 'auto',
        padding: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#183F46',
    },
    innerContainer: {
        flex: .5,
        justifyContent: 'space-between',
    },
    symbol: {
        fontSize: 80,
        lineHeight: 80,
        color: 'white',
    },
    statusText: {
        color: 'white',
        textTransform: 'uppercase',
        fontSize: 20,
    },
    infoText: {
        color: 'white',
        fontSize: 16,
    }
})