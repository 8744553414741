import useAdmissionStore, { AdmissionType } from "../stores/AdmissionStore";
import useTicketStore from "../stores/TicketStore";

const useAdmissionControl = () => {

    const setAdmissionType = async (admissionType: AdmissionType) => {
        // Tickets auf den Server synchronisieren
        let success = await useTicketStore.getState().syncAllTicketsOfEventToServer();
        if (!success) {
            return false;
        }

        success = await useTicketStore.getState().getTickets(true);

        if (!success) {
            useAdmissionStore.getState().setAdmissionType('offline');
            return false;
        }

        useAdmissionStore.getState().setAdmissionType(admissionType);

        return true;
    }

    return {
        setAdmissionType
    }
}

export default useAdmissionControl 