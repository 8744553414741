import {
  Dimensions,
  Linking,
  StyleSheet,
  Text,
  Image,
  View,
} from "react-native";
import React, { useEffect, useRef, useState } from "react";
import { BarCodeScanner } from "expo-barcode-scanner";
import { getTranslation } from "../util/languages";
import GlobalStyles from "../styles/GlobalStyles";
import STButton from "../components/STButton";
import { ScanFieldType } from "../hooks/useConfig";
import { useNavigation } from "@react-navigation/native";

/**
 * This is the barcode / qrcode scanner screen used to scan
 * the event key, the auth key and the ticket codes.
 *
 * @changed 2023-09-01 15:43:18
 * @author André Mörke
 */

type BarcodeScannerProps = {
  handleBarCodeScanned: (params: {}) => void;
  headline: string;
  subline: string;
  type?: ScanFieldType;
};

const BarcodeScanner = ({
  handleBarCodeScanned,
  headline = "",
  subline = "",
  type = "event",
}: BarcodeScannerProps) => {
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const navigation = useNavigation();
  const [showScanner, setShowScanner] = useState(false)

  useEffect(() => {
    setScanned(false);
    setTimeout(() => {
      setShowScanner(true)
    }, type == 'ticket' ? 1500 : 0)
    const getBarcodeScannerPermission = async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === "granted");
    };
    getBarcodeScannerPermission();

  }, []);

  if (hasPermission === null) {
    return (
      <View style={styles.container}>
        <Text style={[GlobalStyles.blackMediumText, styles.text]}>
          {getTranslation("requesting_camera_permission")}
        </Text>
      </View>
    );
  }

  if (hasPermission === false) {
    return (
      <View style={styles.container}>
        <Text style={[GlobalStyles.blackMediumText, styles.text]}>
          {getTranslation("no_camera_access")}
        </Text>
        <STButton
          title={getTranslation("request_camera_permission")}
          onPress={Linking.openSettings}
          buttonStyles={{ marginTop: 20 }}
        />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.description}>
        <Text style={[GlobalStyles.blackMediumText, styles.headline]}>
          {headline}
        </Text>
        <Text style={[GlobalStyles.blackMediumText, styles.subline]}>
          {subline}
        </Text>
      </View>
      <View style={styles.barcodeWrapper}>
        {showScanner && <BarCodeScanner
          onBarCodeScanned={scanned ? undefined : ({ data }) => {
            setScanned(true);
            handleBarCodeScanned(data);
            navigation.goBack();
          }}
          style={styles.scannerContainer}
        />}
        {!showScanner && <Image source={require('../assets/img/qr.png')} style={{ width: Dimensions.get('screen').width * .8, height: Dimensions.get('screen').width * .8, objectFit: 'contain', marginLeft: Dimensions.get('screen').width * .1 }} />}
      </View>
      <View style={{ flex: 0.3 }}></View>
    </View>
  );
};

export default BarcodeScanner;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  description: {
    flex: 0.25,
    paddingHorizontal: 40,
    paddingTop: 50,
  },
  text: {
    fontSize: 18,
  },
  headline: {
    fontSize: 25,
    marginBottom: 20,
  },
  subline: {
    fontSize: 18,
  },
  scannerContainer: {
    flex: 0,
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
  },
  barcodeWrapper: {
    flex: 0.45,
    overflow: "hidden",
  },
});
