import { StyleSheet } from "react-native";
import { useCallback, useEffect } from "react";
import { getTranslation } from "../util/languages";
import useEventStore from "../stores/EventStore";
import BarcodeScanner from "../components/BarcodeScanner";
import useTicketStore from "../stores/TicketStore";
import { ScanFieldType } from "../hooks/useConfig";
import useSystemStore from "../stores/SystemStore";

/**
 * This is the barcode / qrcode scanner screen used to scan
 * the event key, the auth key and the ticket codes.
 *
 * @changed 2023-09-01 15:43:18
 * @author André Mörke
 */

const BarcodeScannerView = ({ route, navigation }) => {
  const { type } = route.params;

  let headline = getTranslation("event_key_scan");
  let subline = getTranslation("event_key_scan_hint");

  // Da der Barcode nach dem Scan nicht automatisch gelöscht wird damit er weiterhin angezeigt werden kann, muss das hier gemacht werden
  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      useTicketStore.getState().setBarcode('');
    }
    );
    return unsubscribe;

  }, []);

  switch (type) {
    case "auth":
      headline = getTranslation("auth_key_scan");
      subline = getTranslation("auth_key_scan_hint");
      break;
    case "event":
      headline = getTranslation("event_key_scan");
      subline = getTranslation("event_key_scan_hint");
      break;
    case "ticket":
      headline = getTranslation("label_barcode_scan");
      subline = getTranslation("label_barcode_scan_hint");
      break;
  }

  const handleBarCodeScanned = useCallback((code: string) => {
    if (/emergency/.test(code)) {
      useSystemStore.getState().setEmergencyActive(true);
    }
    code = code.replace(/emergency/, '');
    switch (type) {
      case "auth":
        useEventStore.getState().setAuthKey(code);
        break;
      case "event":
        useEventStore.getState().setEventKey(code);
        break;
      case "ticket":
        useTicketStore.getState().setBarcode(code);
        break;
    }
  }, []);

  return (
    <BarcodeScanner
      handleBarCodeScanned={handleBarCodeScanned}
      headline={headline}
      subline={subline}
      type={type}
    />
  );
};

export default BarcodeScannerView;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  text: {
    fontSize: 18,
  },
});
