import { useEffect } from "react";
import useEventStore, { EventState } from "../stores/EventStore";
import useSystemStore from "../stores/SystemStore";
import { useNavigation } from "@react-navigation/native";

/**
 * Hook contains the logic for the AuthKeyView and the EventKeyView
 * 
 * @param type event | auth
 * @returns isLoading boolean
 */

export const useAuthView = (type: 'event' | 'auth') => {
    const navigation = useNavigation();

    const { setAuthKey,
        setEventKey,
        authKey,
        eventKey,
        findEvent,
        isLoading,
        eventIsValid,
        getEventsAuthKey,
        setEventIsValid,
        eventValidationError,
        setEventValidationError
    } =
        useEventStore((state: EventState) => ({
            setAuthKey: state.setAuthKey,
            setEventKey: state.setEventKey,
            authKey: state.auth_key,
            eventKey: state.event_key,
            findEvent: state.findEvent,
            isLoading: state.isLoading,
            getEventsAuthKey: state.getEventsAuthKey,
            eventIsValid: state.eventIsValid,
            setEventIsValid: state.setEventIsValid,
            eventValidationError: state.eventValidationError,
            setEventValidationError: state.setEventValidationError
        }));
    // Bei Fokus der Seite wird der AuthKey aus dem Store gelöscht
    useEffect(() => {
        const subscriber = navigation.addListener("focus", (e) => {
            const routes = navigation.getState()?.routes;
            const prevRoute = routes[routes.length - 2];
            const curRoute = routes[routes.length - 1];
            if (curRoute.name === 'AuthKeyView') {
                useSystemStore.getState().setStep(2);
            } else if (curRoute.name === 'VAKeyView') {
                useSystemStore.getState().setStep(1);
            }
            // Wenn er von der selben Route kommt, dann den Event bzw. AuthKey nicht löschen
            if (type === 'auth') {
                if (curRoute && curRoute.name === 'AuthKeyView') return;
                setAuthKey("");
            }

        });
        return subscriber;
    }, []);

    // Wenn der AuthKey passt, werden Eventkey und Authkey an den Server geschickt zum verifizieren der Veranstaltung
    useEffect(() => {
        if (type == 'auth') {
            if (authKey && authKey.length === 13) {
                findEvent();
            }
        } else if (type == 'event') {
            if (eventKey && eventKey.length === 13) {
                const authKey = getEventsAuthKey();
                if (authKey) {
                    setAuthKey(authKey);
                    findEvent();
                } else {
                    navigation.navigate("AuthKeyView");
                }
            }
        }
    }, [authKey, eventKey]);

    // Event wurde erfolgreich vom Server verifiziert
    useEffect(() => {
        if (eventIsValid === true) {
            setEventIsValid(false); // Für eventuelles späteres erneutes verifizieren
            navigation.navigate("OverView");
        } else if (eventValidationError === true) {
            setEventValidationError(false);
            navigation.navigate("VAKeyView");
        }
    }, [eventIsValid, eventValidationError]);

    return { isLoading };
}