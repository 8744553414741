import { Pressable, StyleSheet, Text, View, SafeAreaView } from 'react-native'
import React, { useEffect } from 'react'
import useAlertStore, { AlertStore } from '../stores/AlertStore'
import Animated, { Easing, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import GlobalStyles from '../styles/GlobalStyles'
import { getTranslation } from '../util/languages'
import { get } from 'react-native/Libraries/TurboModule/TurboModuleRegistry'

/**
 * Common alert component to display errors and other messages
 * 
 * @author André Mörke (ö_konzept)
 * @change 2023-09-27 10:24:13 Initial creation
 * @dependencies react-native-reanimated, react-native
 */

const Alert = () => {
    const opacity = useSharedValue(0)
    const top = useSharedValue(-100)

    const config = {
        duration: 500,
        easing: Easing.bezier(0.5, 0.01, 0, 1),
    }

    const { showAlert, Alert, closeAlert } = useAlertStore((state: AlertStore) => ({
        showAlert: state.showAlert,
        Alert: state.Alert,
        closeAlert: state.closeAlert,
    }))

    const style = useAnimatedStyle(() => {
        return {
            opacity: withTiming(opacity.value, config),
            top: withTiming(top.value, config),
        }
    })

    useEffect(() => {
        if (showAlert) {
            opacity.value = 1
            top.value = 50
        } else {
            opacity.value = 0
            top.value = -100
        }
    }, [showAlert])

    if (Alert) {

    }
    const titleColor = {
        'error': 'red',
        'success': 'green',
        'warning': 'orange',
        'info': 'white'
    }[Alert?.type || 'info']

    const firstLetterUpperCase = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    const languageMapping = {
        'error': getTranslation('label_title_error'),
        'success': getTranslation('label_title_success'),
        'warning': getTranslation('label_title_warning'),
        'info': getTranslation('label_title_info')
    }

    return (
        Alert &&
        <Animated.View style={[styles.container, style]}>
            <View style={[styles.titleBar, { backgroundColor: titleColor }]}>
                <Text style={[GlobalStyles.blackBoldText, { fontSize: 20, color: Alert.type == 'info' || !Alert.type ? 'black' : 'white' }]}>{Alert.title ? Alert.title : languageMapping[Alert.type || 'info']}</Text>
                <Pressable onPress={closeAlert} style={({ pressed }) => [styles.closer, { opacity: pressed ? .5 : 1 }]}>
                    <Text style={[GlobalStyles.symbol]}></Text>
                </Pressable>
            </View>
            <View style={styles.body}>
                <Text>{Alert.message}</Text>
            </View>
        </Animated.View>
    )
}

export default Alert

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        position: 'absolute',
        top: 5,
        left: '2%',
        right: '2%',
        zIndex: 100,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 7,
        borderWidth: 0,
        shadowRadius: 3,
        shadowColor: '#171717',
        shadowOpacity: .2,
        shadowOffset: {
            width: 2,
            height: 4
        },
    },
    titleBar: {
        width: '100%',
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        alignItems: 'center',
        backgroundColor: '#EDEDED',
        borderTopWidth: 2,
        borderTopColor: 'lightgray',
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: 'lightgray'
    },
    body: {
        padding: 20,
    },
    closer: {
        position: 'absolute',
        right: 10,
        top: 10,
        width: 30,
        height: 30,
        borderRadius: 15,
        backgroundColor: 'rgba( 0, 0, 0, 0.3 ) )',
        justifyContent: 'center',
        alignItems: 'center'
    }
})