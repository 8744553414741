import { getLocales } from 'expo-localization'

/**
 * 
 * @returns 
 */


export const getLanguage = () => {
    const locales = getLocales();
    const language = locales[0].languageCode;
    return language;
}

const languages = {
    de: {
        label_server_connect_failed: 'Verbindung zum Server fehlgeschlagen',
        label_ticket_refresh_success: 'Ticket-Codes aktualisiert',
        label_title_success: 'Erfolg',
        label_title_error: 'Fehler',
        label_title_warning: 'Warnung',
        label_title_info: 'Info',
        label_online_activated: 'Online-Einlass aktiviert',
        label_offline_activated: 'Offline-Einlass aktiviert',
        event_key_scan: 'Veranstaltungs-Key scannen',
        event_key_scan_hint: 'Halte die Kamera über den Veranstaltungs-Key zum scannen',
        auth_key_scan: 'Auth-Key scannen',
        auth_key_scan_hint: 'Halte die Kamera über den Auth-Key zum scannen',
        label_barcode_scan: 'Barcode scannen',
        label_barcode_scan_hint: 'Halte die Kamera über den QR-Code oder den Barcode zum scannen',
        event_key_needed: 'Veranstaltungs-Key benötigt',
        event_key_placeholder: 'Veranstaltungs-Key',
        ticket_code_placeholder: 'Ticket-Code',
        auth_key_needed: 'Auth-Key benötigt',
        auth_key_placeholder: 'Auth-Key',
        label_close: 'Schließen',
        guide_step_headline: ["", "Im ersten Schritt muss festgelegt werden, für welche Veranstaltung Sie den Einlass kontrollieren möchten.", "Im zweiten Schritt muss festgelegt werden, für welche Veranstaltung Sie den Einlass kontrollieren möchten.", "Im dritten Schritt sehen Sie den aktuellen Einlassstatus.", "Im vierten Schritt können Sie den Einlass zum Ticket vornehmen."],
        guide_step_text: ["", "Scannen sie dafür den entsprechenden Veranstaltungs-Code ab, geben Sie ihn manuell ein oder nutzen Sie die Kamera zum einlesen.", "Scannen sie dafür den entsprechenden Authorisierungs-Key ab, geben Sie ihn manuell ein oder nutzen Sie die Kamera zum einlesen.", "Sie können zwischen dem Online und Offline-Einlass wechseln oder den Einlass starten/fortsetzen.", "Scannen sie dafür den entsprechenden Ticket-Code ab, geben Sie ihn manuell ein oder nutzen Sie die Kamera zum einlesen."],
        imprint_headline: "Herausgeber",
        imprint_text: "ö_konzept\nAgentur für Werbung und Kommunikation GmbH & Co. KG\nAudistraße 3\n08058 Zwickau\n\nTelefon: + 49 (0) 375 211 94 10\nTelefax: + 49 (0) 375 211 94 19\nE-Mail: info@scantickets.de\n\nSitz: Zwickau\nAmtsgericht Chemnitz: HRA 2608\nUst.-IdNr.: DE 177766155\nSteuernummer: 226 / 160 / 05638\n\nPersönlich haftende Gesellschafterin:\nAgentur für Werbung und Kommunikation Beteiligungsgesellschaft mbH\nAmtsgericht Chemnitz: HRB 12402\n Geschäftsführer: Lars Hedwig",
        no_camera_access: "Kamera-Zugriff verweigert",
        request_camera_permission: "Kamerazugriff erteilen",
        requesting_camera_permission: "Kamera-Zugriff anfragen",
        label_back: "Zurück",
        admissionStateOnline: "Online-Einlass",
        admissionStateOffline: "Offline-Einlass",
        dataFoundLocationOnline: 'Einlassdaten wurden online gefunden',
        dataFoundLocationOffline: 'Einlassdaten wurden auf diesem Gerät gefunden',
        error_common: 'Es ist ein Fehler aufgetreten',
        error_3002: "Veranstaltungs-Key oder Auth-Key fehlerhaft",
        error_3003: "Veranstaltungs-Key oder Auth-Key fehlerhaft",
        error_3004: "Server-Fehler",
        error_3005: "Server-Fehler",
        error_4006: "Dieser Veranstaltungs-Key existiert nicht oder der Authentifizierungs-Key ist ungültig.",
        error_3012: "Veranstaltungs-Key oder Auth-Key fehlerhaft",
        error_3013: "Veranstaltungs-Key oder Auth-Key fehlerhaft",
        error_3014: "Server-Fehler",
        error_ticket_4006: "Ticket nicht gefunden!",
        error_ticket_6006: "Keine Tickets geladen!",
        error_ticket_6007: "Ticket nicht bezahlt!",
        error_ticket_6008: "Ticket storniert!",
        error_ticket_6009: "Ticket bereits gescannt!",
        error_today_word: 'Ticket (heute) ',
        label_start_admission: 'Einlass beginnen',
        label_refresh_ticket_codes: 'Ticket-Codes aktualisieren',
        label_switch_to_offline: 'Offline-Modus aktivieren',
        label_switch_to_online: 'Online-Modus aktivieren',
        label_scanned_tickets: 'Ticket(s) gescannt',
        label_switch_admission_type_offline: 'Wechsel zu Offline-Einlass',
        label_switch_admission_type_online: 'Wechsel zu Online-Einlass',
        label_loading_ticket_data: 'Aktualisiere Ticket-Daten',
        label_loading_standard: 'Lädt',
        label_of: 'von',
        label_overview_scanned_tickets: 'Übersicht gescannter Tickets',
        label_valid: 'Gültig',
        label_invalid: 'Ungültig',
        label_bought: 'Gekauft',
        label_payed: 'Bezahlt',
        label_delivery: 'Zustellung',
        label_scanned: 'Gescannt',
        label_cancellation: 'Stornierung',
        label_ticket_buyer: 'Ticketkäufer',
        label_ticket_type: 'Ticket',
        label_emergency_system: 'ACHTUNG! Dies ist das Notfallsystem, welches nur auf Anweisung durch den ScanTickets.de - Support benutzt werden darf! (Hotline: + 49 375 211 94 33)',
        label_install_pwa: 'Als App installieren',
        label_ticket_order_info: 'Ticket-Bestell-Informationen',
    },
    en: {
        label_server_connect_failed: 'Connection to server failed',
        label_ticket_refresh_success: 'Ticket codes refreshed',
        label_title_success: 'Success',
        label_title_error: 'Error',
        label_title_warning: 'Warning',
        label_title_info: 'Info',
        label_online_activated: 'Online entry activated',
        label_offline_activated: 'Offline entry activated',
        event_key_scan: 'Scan Event-Key',
        event_key_scan_hint: 'Hold the camera over the event key to scan',
        auth_key_scan: 'Scan Auth-Key',
        auth_key_scan_hint: 'Hold the camera over the auth key to scan',
        label_barcode_scan: 'Scan Barcode',
        label_barcode_scan_hint: 'Hold the camera over the QR code or barcode to scan',
        event_key_needed: 'Event-Key needed',
        ticket_code_placeholder: 'Ticket-Code',
        event_key_placeholder: 'Event-Key',
        auth_key_needed: 'Auth-Key needed',
        auth_key_placeholder: 'Auth-Key',
        label_close: 'Close',
        guide_step_headline: ["", "The first step is to determine which event you want to control admission to.", "The second step is to determine which event you want to control admission for.", "In the third step you will see the current admission status.", "In the fourth step you can take the ticket."],
        guide_step_text: ["", "To do this, scan the corresponding event code, enter it manually or use the camera to read it in.", "To do this, scan the corresponding authorization key, enter it manually or use the camera to read it.", "You can switch between online and offline entry or start/continue entry.", "To do this, scan the corresponding ticket code, enter it manually or use the camera to read it."],
        imprint_headline: "Editor",
        imprint_text: "ö_konzept\nAgentur für Werbung und Kommunikation GmbH & Co. KG\nAudistraße 3\n08058 Zwickau\n\nPhone: + 49 (0) 375 211 94 10\nTelefax: + 49 (0) 375 211 94 19\nE-Mail: info@scantickets.de\n\nLocation: Zwickau\nAmtsgericht Chemnitz: HRA 2608\nVAT ID no.: DE 177766155\nTax number: 226 / 160 / 05638\n\nPersonally liable partner:\nAgentur für Werbung und Kommunikation Beteiligungsgesellschaft mbH\nDistrict Court Chemnitz: HRB 12402\n CEO: Lars Hedwig",
        no_camera_access: "Camera access denied",
        request_camera_permission: "Grant camera access",
        requesting_camera_permission: "Requesting camera access",
        label_back: "Back",
        admissionStateOnline: "Online admission",
        admissionStateOffline: "Offline admission",
        dataFoundLocationOnline: 'Admissiondata was found online',
        dataFoundLocationOffline: 'Admissiondata was found on this device',
        error_common: 'An error has occurred',
        error_3002: "Event key or auth key incorrect",
        error_3003: "Event key or auth key incorrect",
        error_3004: "Server error",
        error_3005: "Server error",
        error_4006: "This event key does not exist or the authentication key is invalid.",
        error_3012: "Event key or auth key incorrect",
        error_3013: "Event key or auth key incorrect",
        error_3014: "Server-Error",
        error_ticket_4006: "Ticket not found!",
        error_ticket_6006: "No tickets loaded!",
        error_ticket_6007: "Ticket not paid!",
        error_ticket_6008: "Ticket cancelled!",
        error_ticket_6009: "Ticket already scanned!",
        error_today_word: 'Ticket (today)',
        label_start_admission: 'Start admission',
        label_refresh_ticket_codes: 'Refresh ticket codes',
        label_switch_to_offline: 'Switch to offline mode',
        label_switch_to_online: 'Switch to online mode',
        label_scanned_tickets: 'Ticket(s) scanned',
        label_switch_admission_type_offline: 'Switch to offline admission',
        label_switch_admission_type_online: 'Switch to online admission',
        label_loading_ticket_data: 'Refreshing ticket data',
        label_loading_standard: 'Loading',
        label_of: 'of',
        label_overview_scanned_tickets: 'Overview of scanned tickets',
        label_valid: 'Valid',
        label_invalid: 'Invalid',
        label_bought: 'Bought',
        label_payed: 'Payed',
        label_delivery: 'Delivery',
        label_scanned: 'Scanned',
        label_cancellation: 'Cancellation',
        label_ticket_buyer: 'Ticket buyer',
        label_ticket_type: 'Ticket',
        label_emergency_system: 'ATTENTION! This is the emergency system, which may only be used on instructions from ScanTickets.de support! (Hotline: + 49 375 211 94 33)',
        label_install_pwa: 'Install app',
        label_ticket_order_info: 'Ticket order information',
    }
} as const;

type TypeOfLanguages = keyof typeof languages.de;

export const getTranslation = (key: TypeOfLanguages) => {
    const language = getLanguage();
    return languages[language][key];
}