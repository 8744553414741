import { create } from "zustand";
import { devtools } from "zustand/middleware";


export type Alert = {
    title?: string;
    message: string;
    type?: 'success' | 'error' | 'warning' | 'info';
}


export type AlertStore = {
    Alert: Alert | null;
    showAlert: boolean;
    setAlert: (alert: Alert) => void;
    closeAlert: () => void;
}


const useAlertStore = create<AlertStore>()(
    devtools(
        (set) => ({
            Alert: null,
            showAlert: false,
            type: 'info',
            closeAlert: () => set({ showAlert: false }),
            setAlert: (alert: Alert) => {
                set({ Alert: alert, showAlert: true })
                setTimeout(() => {
                    set({ showAlert: false })
                }, /error|warning/.test(alert.type) ? 10000 : 3000)
            },
        }
        )
    )
);

export default useAlertStore;