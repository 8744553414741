import { Button, StyleSheet, Text, View } from "react-native";
import React from "react";
import ColorStripe from "../../components/ColorStripe";
import GlobalStyles from "../../styles/GlobalStyles";
import { getTranslation } from "../../util/languages";
import useSTStorage from "../../hooks/useStStorage";

const LawPage = () => {
  const { clearAllDataFromStorage } = useSTStorage();
  return (
    <>
      <ColorStripe />
      <View style={styles.container}>
        <Text style={[GlobalStyles.blackMediumText, styles.headline]}>
          {getTranslation("imprint_headline")}
        </Text>
        <Text style={[GlobalStyles.blackLightText, styles.text]}>
          {getTranslation("imprint_text")}
        </Text>
      </View>
    </>
  );
};

export default LawPage;

const styles = StyleSheet.create({
  container: {
    padding: 20,
    flex: 1,
    backgroundColor: "white",
    justifyContent: "center",
  },
  headline: {
    fontSize: 30,
    textAlign: "center",
    marginBottom: 20,
  },
  text: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 20,
  },
});
